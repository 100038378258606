import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';
import { LastESignStateCd } from 'types/view/eDoc';

export const ESIGN_HISTORY_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '18.2rem',
		label: '마지막 활동 일시',
		sortKey: '발송일시',
	},
	{
		width: '30rem',
		label: '문서유형',
		sortKey: '문서유형',
	},
	{
		width: '32rem',
		label: '제목',
		sortKey: '제목',
	},
	{
		width: '20rem',
		label: '구성원',
		sortKey: '구성원',
	},
	{
		width: '12rem',
		label: '발송자',
		sortKey: '발송자',
	},
];

export const SIGN_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: LastESignStateCd.작성대기,
		label: '작성 대기',
		color: 'yellow',
	},
	{
		key: LastESignStateCd.서명대기,
		label: '서명 대기',
		color: 'yellow',
	},
	{
		key: LastESignStateCd.검수대기,
		label: '검수 대기',
		color: 'blue',
	},
	{
		key: LastESignStateCd.완료,
		label: '완료',
		color: 'green',
	},
	{
		key: LastESignStateCd.서명취소,
		label: '서명 취소',
		color: 'red',
	},
	{
		key: LastESignStateCd.기간만료,
		label: '기간 만료',
		color: 'red',
	},
	{
		key: LastESignStateCd.상태오류,
		label: '상태 오류',
		color: 'red',
	},
];
