import styled from 'styled-components';

export const Container = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding: 1.2rem 2.4rem;
	box-sizing: border-box;

	&:hover,
	&:focus,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
		color: inherit;
	}
`;

export const Title = styled.h4`
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const Icon = styled.img<{ $disabled?: boolean }>`
	width: 2.4rem;
	height: 2.4rem;

	${(props) => props.$disabled && props.theme.filters.gray70};
`;
