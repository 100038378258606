import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { PageInfo } from 'types/view/base';
import { CheckOption } from 'components/base/Selections/type';
import {
	useCenterManagers,
	useCommonCodes,
	useMyAccountInfo,
	useScheduleTagAutomationHistory,
} from 'lib/hook/react-query';
import { Filter } from 'types/view/filter';
import TagAutomationTable from 'components/domain/table/TagAutomationTable';
import CRTableHeader from 'components/base/CRTableHeader';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import useDateFilter from 'lib/hook/util/useDateFilter';
import * as S from './styles';

export default function TagAutomationTab() {
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const [date, setDate] = useDateFilter();
	const [keyword, setKeyword] = useState('');
	const { filters: currentFilter, setFilters: setCurrentFilter } = usePageFilter({});
	const [searchValue, setSearchValue] = useState('');
	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN006', 'CMN151', 'CMN089', 'CMN153'],
	});
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const {
		data: tagAutomationHistory,
		isLoading: tagAutomationHistoryLoading,
		refetch,
	} = useScheduleTagAutomationHistory({
		page: pageInfo.page,
		size: pageInfo.size,
		startServiceYmd: currentFilter?.rangeFilter?.[0].value?.start
			? dayjs(currentFilter?.rangeFilter[0].value.start).format('YYYYMMDD')
			: dayjs(date).startOf('M').format('YYYYMMDD'),
		endServiceYmd: currentFilter?.rangeFilter?.[0].value?.end
			? dayjs(currentFilter?.rangeFilter[0].value.end).format('YYYYMMDD')
			: dayjs(date).endOf('M').format('YYYYMMDD'),
		serviceTypeCd: currentFilter?.serviceTypeFilter?.map((item) => item?.value),
		planEditYn: currentFilter?.planEditYnFilter?.map((item) => item?.value),
		tagErrType: currentFilter?.tagErrTypeFilter?.map((item) => item?.value),
		tagErrReason: currentFilter?.tagErrReasonFilter?.map((item) => item?.value),
		managerId: currentFilter?.managerFilter?.map((item) => item?.value),
		autoProcessStatus: currentFilter?.automationProcessStatusFilter?.map((item) => item?.value),
		centerId: myAccountInfo?.centerId ? [myAccountInfo.centerId] : undefined,
		keyword,
	});

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};
	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleChangeDate = (date: Date) => {
		setDate(date);
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
	};

	const serviceTypeFilter = useMemo(
		() =>
			({
				key: 'serviceTypeFilter',
				type: 'multi',
				placeholder: '급여종류',
				options: (commonCodes || [])
					?.filter((item) => item.comCdGroupNm === 'CMN006' && item.etcDesc1 === 'Y')
					?.map((code) => ({
						label: code.korComCdAliasNm,
						value: code.comCdId,
					})),
			} as Filter<CheckOption>),
		[commonCodes],
	);

	const automationProcessStatusFilter = useMemo(
		() =>
			({
				key: 'automationProcessStatusFilter',
				type: 'multi',
				placeholder: '자동화 결과',
				options: (commonCodes || [])
					?.filter((item) => item.comCdGroupNm === 'CMN153')
					?.map((code) => ({
						label: code.korComCdAliasNm,
						value: code.comCdId,
					})),
			} as Filter<CheckOption>),
		[commonCodes],
	);

	const rangeFilter = useMemo(
		() =>
			({
				key: 'rangeFilter',
				type: 'calendar',
				placeholder: '일자',
			} as Filter<CheckOption>),
		[],
	);

	const planEditYnFilter = useMemo(
		() =>
			({
				key: 'planEditYnFilter',
				type: 'multi',
				placeholder: '일정 상태',
				options: [
					{
						label: '정상',
						value: false,
					},
					{
						label: '수정',
						value: true,
					},
				],
			} as Filter<CheckOption>),
		[],
	);

	// commonCodes
	const tagErrTypeFilter = useMemo(
		() =>
			({
				key: 'tagErrTypeFilter',
				type: 'multi',
				placeholder: '태그 상태',
				options: (commonCodes || [])
					?.filter((item) => item.comCdGroupNm === 'CMN151')
					?.map((code) => ({
						label: code.korComCdAliasNm,
						value: code.comCdId,
					})),
			} as Filter<CheckOption>),
		[commonCodes],
	);

	const tagErrReasonFilter = useMemo(
		() =>
			({
				key: 'tagErrReasonFilter',
				type: 'multi',
				placeholder: '태그 유형',
				options: (commonCodes || [])
					?.filter((item) => item.comCdGroupNm === 'CMN089')
					?.map((code) => ({
						label: code.korComCdAliasNm,
						value: code.comCdId,
					})),
			} as Filter<CheckOption>),
		[commonCodes],
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options:
					centerManagers?.map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})) ?? [],
				placeholder: '사회복지사',
			} as Filter<CheckOption>),
		[centerManagers],
	);

	const handleSearchValue = (value: string) => setSearchValue(value);

	const tagAutomationHistoryData = useMemo(
		() => tagAutomationHistory?.content ?? [],
		[tagAutomationHistory],
	);

	const handleRefresh = () => {
		refetch();
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>태그 수정 자동화 내역이 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					해당 월 일정은 아직 수정되지 않았습니다.
					<br />
					궁금한 점은 관리자에게 문의하세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	useEffect(() => {
		if (tagAutomationHistory?.totalPages !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: tagAutomationHistory?.totalPages || 0,
			}));
		}
	}, [tagAutomationHistory?.totalPages]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo?.memberAccountId]);

	return (
		<CRTable.BackBlind>
			<S.HeaderContainer>
				<CRTableMonthSelector value={date} onChange={handleChangeDate} />
			</S.HeaderContainer>
			<S.ContentContainer>
				<CRTableHeader
					onChangePageInfo={handleChangePageInfo}
					pageInfo={pageInfo}
					currentFilter={currentFilter}
					stickyMode
					filters={[
						serviceTypeFilter,
						rangeFilter,
						planEditYnFilter,
						tagErrTypeFilter,
						tagErrReasonFilter,
						automationProcessStatusFilter,
						managerFilter,
					]}
					searchValue={searchValue}
					placeholder='수급자명으로 검색'
					onChangeSearchValue={handleSearchValue}
					setCurrentFilter={setCurrentFilter}
					onSearch={handleSearch}
					onRefresh={handleRefresh}
					showRefresh
					showViewCount
				/>
				<S.InnerContainer>
					{tagAutomationHistoryLoading ? (
						<CRSpinner />
					) : tagAutomationHistoryData && tagAutomationHistoryData?.length > 0 ? (
						<TagAutomationTable items={tagAutomationHistoryData} />
					) : (
						renderEmpty
					)}
				</S.InnerContainer>
			</S.ContentContainer>
		</CRTable.BackBlind>
	);
}
