import React, { useMemo } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import {
	useContractDetail,
	useContractDocument,
	useContractSendStatus,
	useGetEmployeeDocument,
	useGetRecipientDocument,
} from 'lib/hook/react-query';
import { useParams } from 'react-router-dom';
import { displayBirthDayId } from 'lib';
import CRStatus from 'components/base/CRStatus';
import { CONTRACT_LIST_TABLE_HEADER } from 'components/domain/form/ContractDocumentForm/constant';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import CRButton from 'components/base/CRButton';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import InformationTable from 'components/ui/InformationTable';
import CRRequiredMark from 'components/base/CRRequiredMark';
import { ContractDetailServiceDTO } from 'types/dto';
import { LastESignStateCd } from 'types/view/eDoc';
import * as S from './styles';

function ContractSendViewPage(): React.ReactElement {
	const { showDialog } = useGlobalLayout();
	const params = useParams<{ contractId: string }>();
	const { data: detail } = useContractDetail({
		serviceContractId: Number(params.contractId),
	});
	const { data: contractSendStatus } = useContractSendStatus({
		serviceContractId: Number(params.contractId),
	});

	const { data: contractDocument } = useContractDocument({
		serviceContractIds: [Number(params.contractId)],
	});

	const getRecipientDocument = useGetRecipientDocument();
	const getEmployeeDocument = useGetEmployeeDocument();

	const contractEdocSendResponse = (contractSendStatus ?? []).find(
		(item) => item.paperTypeCd === 'CMN119.0058',
	)?.contractEdocSendResponse;

	const sendList = useMemo(() => {
		const contractEdocSendResponse = (contractSendStatus ?? []).find(
			(item) => item.paperTypeCd === 'CMN119.0058',
		)?.contractEdocSendResponse;

		const recipientSend = {
			title: `수급자 ${detail?.recipient?.base.recipientNm}(${displayBirthDayId(
				detail?.recipient?.base.birthDt,
			)})`,
			items: [
				{
					type: 'recipient',
					title: '수급자 계약서',
					isRequired: true,
					fileName: '수급자_계약서.eform',
					status: contractEdocSendResponse?.lastEsignStateCd ?? 'CMN031.10',
					data: {
						serviceContractId: Number(params.contractId),
						receiverName: detail?.recipient?.base.recipientNm,
					},
					document: contractDocument?.find((item) => item.paperTypeCd === 'CMN119.0058'),
				},
			] as {
				type: 'employee' | 'recipient';
				title: string;
				isRequired: boolean;
				fileName: string;
				status: string;
				data: any;
				document: any;
			}[],
		};

		const employeeSend = (detail?.employees ?? []).map((item) => ({
			title: `직원 ${item.base.employeeNm}(${displayBirthDayId(item.base.employeeBirthDt)})`,
			items: detail?.services?.reduce(
				(accumulate: any[], service: ContractDetailServiceDTO) => {
					const exist = service.associates?.contractEmployees?.find(
						(employee) => employee?.employeeId === item.base.employeeId,
					);
					if (exist) {
						const contractEdocSendResponse = (contractSendStatus ?? []).find(
							(status) =>
								status.paperTypeCd === 'CMN119.0051' &&
								status?.employeeId === item.base.employeeId &&
								status.serviceTypeCd === service.serviceTypeCd,
						)?.contractEdocSendResponse;

						return [
							...accumulate,
							{
								type: 'employee',
								title: '직원 계약서',
								isRequired: true,
								fileName: '직원_계약서.eform',
								status: contractEdocSendResponse?.lastEsignStateCd ?? 'CMN031.10',
								data: {
									serviceContractId: Number(params.contractId),
									employeeId: item.base.employeeId,
									serviceTypeCd: service.serviceTypeCd,
									monthPerWorkHourCnt: Number(service?.use?.monthPerWorkHourCnt ?? '0'),
									receiverName: item.base.employeeNm,
								},
								document: contractDocument?.find(
									(target) =>
										target.paperTypeCd === 'CMN119.0051' &&
										target?.employeeId === item.base.employeeId &&
										target.serviceTypeCd === service.serviceTypeCd,
								),
							},
						];
					}

					return accumulate;
				},
				[] as {
					type: 'employee' | 'recipient';
					title: string;
					isRequired: boolean;
					fileName: string;
					status: string;
					data: any;
					document: any;
				}[],
			),
		}));

		return [recipientSend, ...employeeSend];
		// return [recipientSend];
	}, [contractEdocSendResponse, detail, contractSendStatus, contractDocument]);

	const handleClickPreview = async (item: any) => {
		if (item.type === 'recipient') {
			const data = await getRecipientDocument.mutateAsync({
				recipientContractId: item.document.contractDocumentId,
			});

			if (!data) return;
			showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={JSON.parse(data)} />);
			return;
		}

		const data = await getEmployeeDocument.mutateAsync({
			employeeContractId: item.document.contractDocumentId,
		});

		if (!data) return;
		showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={JSON.parse(data)} />);
	};

	return (
		<S.PageContainer>
			<Accordion.Root type='multiple' defaultValue={['send']}>
				<Accordion.Item value='send' asChild>
					<TaskAccordion.Item>
						<Accordion.Header asChild>
							<Accordion.Trigger asChild>
								<TaskAccordion.Trigger>발송</TaskAccordion.Trigger>
							</Accordion.Trigger>
						</Accordion.Header>
						<Accordion.Content asChild>
							<TaskAccordion.Content>
								<S.Container>
									{sendList.map((item) => (
										<S.SectionContainer>
											<S.SectionTitle>{item.title}</S.SectionTitle>
											<InformationTable
												items={[
													CONTRACT_LIST_TABLE_HEADER,
													...(item.items
														? [
																...item.items.map(
																	(send) =>
																		[
																			{
																				type: 'value',
																				value: (
																					<div>
																						{send.title} {send.isRequired && <CRRequiredMark />}
																					</div>
																				),
																			},
																			{
																				type: 'value',
																				value: send.fileName,
																			},
																			{
																				type: 'value',
																				value: (
																					<CRStatus
																						options={[
																							{
																								key: 'CMN031.10',
																								label: '발송전',
																								color: 'gray',
																							},
																							{
																								key: LastESignStateCd.서명대기,
																								label: '서명 대기',
																								color: 'yellow',
																							},
																							{
																								key: LastESignStateCd.검수대기,
																								label: '검수 대기',
																								color: 'blue',
																							},
																							{
																								key: LastESignStateCd.완료,
																								label: '완료',
																								color: 'green',
																							},
																							{
																								key: LastESignStateCd.서명취소,
																								label: '서명 취소',
																								color: 'red',
																							},
																							{
																								key: LastESignStateCd.기간만료,
																								label: '기간 만료',
																								color: 'red',
																							},
																						]}>
																						{send.status}
																					</CRStatus>
																				),
																			},
																			{
																				type: 'value',
																				value: (
																					<S.ButtonContainer>
																						{send.document && (
																							<CRButton.Default
																								size='xSmall'
																								type='outlined'
																								palette='gray'
																								onClick={() => handleClickPreview(send)}>
																								미리보기
																							</CRButton.Default>
																						)}
																					</S.ButtonContainer>
																				),
																			},
																		] as InformationTableItemType[],
																),
														  ]
														: []),
												]}
											/>
										</S.SectionContainer>
									))}
								</S.Container>
							</TaskAccordion.Content>
						</Accordion.Content>
					</TaskAccordion.Item>
				</Accordion.Item>
			</Accordion.Root>
		</S.PageContainer>
	);
}

export default React.memo(ContractSendViewPage);
