import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import Assets from 'assets';
import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import CRButton from 'components/base/CRButton';

import * as S from './styles';

interface Props {
	disabled: boolean;
}

function EDocWorkExecuteLogIASalaryOfferPlanForm({ disabled }: Props): React.ReactElement {
	const [isOpenRecipientInfo, setIsOpenRecipientInfo] = useState(false);
	const { form } = useWorkExecuteInspectionLog();

	const handleOpenRecipientInfo = () => {
		setIsOpenRecipientInfo((prev) => !prev);
	};

	return (
		<S.Container>
			<CRButton.IconButton
				onClick={handleOpenRecipientInfo}
				iconRight={
					isOpenRecipientInfo ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom
				}
				palette='gray'
				type='outlined'
				size='large'
				fullSize>
				작성 내용 보기
			</CRButton.IconButton>
			{isOpenRecipientInfo && (
				<>
					<S.TableTitle>
						<CRText text='욕구사정' color='gray00' typography='bodyB' />
					</S.TableTitle>
					<CRText text='① 신체상태' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={1000}
								placeholder='신체상태 입력'
							/>
						)}
						name='physicalConditionDesc'
						control={form?.control}
					/>
					<CRText text='② 질병' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={1000}
								placeholder='질병 입력'
							/>
						)}
						name='diseaseDesc'
						control={form?.control}
					/>
					<CRText text='③ 인지상태' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={1000}
								placeholder='인지상태 입력'
							/>
						)}
						name='recogStateDesc'
						control={form?.control}
					/>
					<CRText text='④ 의사소통' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={1000}
								placeholder='의사소통 입력'
							/>
						)}
						name='communicationDesc'
						control={form?.control}
					/>
					<CRText text='⑤ 영양상태' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={1000}
								placeholder='영양상태 입력'
							/>
						)}
						name='nutritionalStateDesc'
						control={form?.control}
					/>
					<CRText text='⑥ 가족 및 환경' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={1000}
								placeholder='가족 및 환경 입력'
							/>
						)}
						name='familyEnvironmentDesc'
						control={form?.control}
					/>
					<CRText text='종합 의견' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={2000}
								placeholder='종합 의견 입력'
							/>
						)}
						name='totalOpinionDesc'
						control={form?.control}
					/>
					<S.TableTitle>
						<CRText text='급여제공계획' color='gray00' typography='bodyB' />
					</S.TableTitle>
					<CRText text='급여목표' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={2000}
								placeholder='급여목표 입력'
							/>
						)}
						name='salaryGoalDesc'
						control={form?.control}
					/>
					<CRText text='필요 급여내용' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={2000}
								placeholder='필요 급여내용 입력'
							/>
						)}
						name='needSalaryDesc'
						control={form?.control}
					/>
					<CRText text='제공방법' color='gray60' typography='label' />
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TextArea
								disabled={disabled}
								onChange={onChange}
								value={value}
								fixedHeight
								height='14.4rem'
								maxLength={2000}
								placeholder='제공방법 입력'
							/>
						)}
						name='provisionMethodDesc'
						control={form?.control}
					/>
				</>
			)}
		</S.Container>
	);
}

export default React.memo(EDocWorkExecuteLogIASalaryOfferPlanForm);
