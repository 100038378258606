import { ToastContainer, ToastOptions, toast } from 'react-toastify';
import React, { ReactNode } from 'react';
import { RuleSet, css, styled } from 'styled-components';
import Assets from 'assets';
import Theme from 'common/theme';
import { ToastType } from './type';
import { CRText } from '../CRText';

// Toast.ts
export const StyledToastContainer = styled(ToastContainer)`
	margin-top: 60px;
	width: auto !important;
	white-space: nowrap;
	position: fixed;
	padding: 4px;
	width: auto !important;
	padding: 0 5rem;
	box-sizing: border-box;
	color: #fff;
	left: 50%;
	bottom: 5rem;
	transform: translateX(-50%);
	.Toastify__toast-body {
		align-items: center;
		gap: 0.2rem;
	}
	.Toastify__toast {
		margin-bottom: 1rem;
		border-radius: 0.8rem;
		display: flex;
		align-items: center;
	}
`;

const defaultToastOption: ToastOptions = {
	position: 'bottom-center',
	autoClose: 2500,
	hideProgressBar: true,
	closeOnClick: true,
	closeButton: false,
};

const icons = {
	error: Assets.icon.info,
	info: Assets.icon.info,
	warning: Assets.icon.warning,
	success: Assets.icon.checkCircle,
};

const ICON_FILTER: { [key in ToastType]: RuleSet<object> } = {
	default: css`
		@media screen and (max-width: 480) {
		}
		filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(4%) hue-rotate(172deg)
			brightness(103%) contrast(101%);
	`,
	warning: css`
		@media screen and (max-width: 480) {
		}
		filter: brightness(0) saturate(100%) invert(25%) sepia(85%) saturate(455%) hue-rotate(6deg)
			brightness(105%) contrast(85%);
	`,
	info: css`
		@media screen and (max-width: 480) {
		}
		filter: brightness(0) saturate(100%) invert(16%) sepia(69%) saturate(1204%) hue-rotate(192deg)
			brightness(98%) contrast(91%);
	`,
	success: css`
		@media screen and (max-width: 480) {
		}
		filter: brightness(0) saturate(100%) invert(26%) sepia(27%) saturate(722%) hue-rotate(93deg)
			brightness(96%) contrast(85%);
	`,
	error: css`
		@media screen and (max-width: 480) {
			width: 20px;
		}
		filter: brightness(0) saturate(100%) invert(10%) sepia(77%) saturate(4515%) hue-rotate(325deg)
			brightness(73%) contrast(129%);
	`,
};

const BANNER_STYLE = {
	default: {
		background: Theme.colors.gray20,
		color: Theme.colors.gray100,
	},
	error: {
		background: Theme.colors.primary90,
		color: Theme.colors.primary20,
	},
	warning: {
		background: Theme.colors.yellowLighten,
		color: Theme.colors.yellowDarken,
	},
	info: {
		background: Theme.colors.blueLighten,
		color: Theme.colors.blueDarken,
	},
	success: {
		background: Theme.colors.greenLighten,
		color: Theme.colors.greenDarken,
	},
};

const Icon = styled.img<{ $type: ToastType }>`
	width: 2.4rem;
	height: 2.4rem;
	${(props) => ICON_FILTER[props.$type]};
`;

const Text = styled.span`
	display: block;
	padding-right: 0.2rem;
	padding-top: 0.2rem;
`;

export const Toast = {
	info: (message: ReactNode, options: ToastOptions = {}) => {
		toast(<Text>{message}</Text>, {
			...defaultToastOption,
			style: {
				...BANNER_STYLE.info,
			},
			closeButton: <Icon src={Assets.icon.close} $type='info' />,
			icon: <Icon src={icons.info} $type='info' />,
			...options,
		});
	},
	success: (message: ReactNode, options: ToastOptions = {}) => {
		toast(<Text>{message}</Text>, {
			...defaultToastOption,
			style: {
				...BANNER_STYLE.success,
			},
			closeButton: <Icon src={Assets.icon.close} $type='success' />,
			icon: <Icon src={icons.success} $type='success' />,
			...options,
		});
	},
	warning: (message: ReactNode, options: ToastOptions = {}) => {
		toast(<Text>{message}</Text>, {
			...defaultToastOption,
			style: {
				...BANNER_STYLE.warning,
			},
			closeButton: <Icon src={Assets.icon.close} $type='warning' />,
			icon: <Icon src={icons.warning} $type='warning' />,
			...options,
		});
	},
	error: (message: ReactNode, options: ToastOptions = {}) => {
		toast(<Text>{message}</Text>, {
			...defaultToastOption,
			style: {
				...BANNER_STYLE.error,
			},
			closeButton: <Icon src={Assets.icon.close} $type='error' />,
			icon: <Icon src={icons.error} $type='error' />,
			...options,
		});
	},
};
