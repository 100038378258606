import React, { CSSProperties, ChangeEvent, useRef } from 'react';

import Assets from 'assets';
import { CheckOption } from 'components/base/Selections/type';
import CRButton from 'components/base/CRButton';
import * as S from './styles';

export type DayTimePicker = {
	id: string | number;
	days: string[];
	startTime: string;
	endTime: string;
};

interface Props {
	style?: CSSProperties;
	options?: CheckOption[];
	startPlaceHolder?: string;
	endPlaceHolder?: string;
	disabled?: boolean;
	showDays?: boolean;
	currentValue?: DayTimePicker;
	onChange?: (item: DayTimePicker) => void;
	onDelete?: (id: string | number) => void;
	iconFill?: boolean;
	timeAlign?: 'left' | 'center';
}

export default function CRDayTimePicker({
	options,
	showDays = true,
	currentValue = {
		id: '',
		days: [],
		startTime: '',
		endTime: '',
	},
	style = {},
	onChange,
	onDelete,
	disabled = false,
	iconFill = false,
	startPlaceHolder = '시작시간',
	endPlaceHolder = '종료시간',
	timeAlign = 'center',
}: Props): React.ReactElement {
	const endTimeRef = useRef<HTMLInputElement>(null);
	const handleClickDay = (item: CheckOption) => {
		if (!currentValue || disabled) return;
		const newDays = currentValue?.days.length ? [...currentValue.days] : [];
		const matchedItemIndex = currentValue.days.findIndex((day) => day === item.value);
		if (matchedItemIndex > -1) {
			newDays.splice(matchedItemIndex, 1);
		} else {
			newDays.push(item.value);
		}
		onChange?.({
			...currentValue,
			days: newDays,
		});
	};

	const handleChangeTime = (e: ChangeEvent<HTMLInputElement>) => {
		const { name } = e.target;
		const numericOnly = e.target.value.replace(/\D/g, '');
		let formattedTime = numericOnly;

		if (numericOnly.length > 2) {
			formattedTime = `${numericOnly.substring(0, 2)}:${numericOnly.substring(2)}`;
		}
		// 시간 제한: 24시 이하, 분 제한: 60분 이하
		if (parseInt(numericOnly.substring(0, 2), 10) > 23) {
			formattedTime = '00:';
		}
		if (parseInt(numericOnly.substring(2), 10) > 59) {
			formattedTime = `${numericOnly.substring(0, 2)}:00`;
		}

		onChange?.({
			...currentValue,
			[name]: formattedTime,
		});
	};

	return (
		<S.Container style={style} $showDays={showDays} $disabled={disabled}>
			{showDays && (
				<S.DayContainer>
					<S.Days>
						{options?.map((option) => {
							const isSelected = currentValue?.days.includes(option.value);

							return (
								<S.Day
									$isDisabled={disabled}
									$isSelected={isSelected}
									key={option.value}
									onClick={() => handleClickDay(option)}>
									{option.label}
								</S.Day>
							);
						})}
					</S.Days>
					{!disabled && (
						<CRButton.Default
							type='outlined'
							palette='gray'
							size='xSmall'
							onClick={() => {
								if (!currentValue || disabled) return;
								onDelete?.(currentValue?.id);
							}}>
							삭제
						</CRButton.Default>
					)}
				</S.DayContainer>
			)}

			<S.TimeContainer>
				<S.Times>
					<S.InputContainer>
						<S.Input
							$timeAlign={timeAlign}
							disabled={disabled}
							placeholder={startPlaceHolder}
							name='startTime'
							value={currentValue?.startTime}
							maxLength={5}
							onChange={handleChangeTime}
						/>
					</S.InputContainer>
					<span>~</span>
					<S.InputContainer>
						<S.Input
							$timeAlign={timeAlign}
							ref={endTimeRef}
							placeholder={endPlaceHolder}
							disabled={disabled}
							name='endTime'
							value={currentValue?.endTime}
							maxLength={5}
							onChange={handleChangeTime}
						/>
					</S.InputContainer>
				</S.Times>
				<S.Icon
					$isDisabled={disabled}
					src={iconFill ? Assets.icon.accessTimeBlack : Assets.icon.accessTime}
					alt='accessTime'
				/>
			</S.TimeContainer>
		</S.Container>
	);
}
