import React, { useEffect, useMemo, useState } from 'react';
import 'dayjs/locale/ko';

import CRInput from 'components/base/CRInput';
import Assets from 'assets';
import useDialog from 'lib/hook/util/useDialog';

import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';

import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { useMyAccountInfo, useSendMessage, useTemplateList } from 'lib/hook/react-query';
import { Controller, useForm } from 'react-hook-form';
import { SendFormViewType, SendTarget } from 'types/view/send';
import SendTargetDialog from 'components/domain/dialog/SendTargetDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import dayjs from 'dayjs';
import { SendMessageRequest } from 'types/api/send';
import { Toast } from 'components/base/CRToast';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRSpinner from 'components/base/CRSpinner';
import * as S from './styles';

function SendMessageTab(): React.ReactElement {
	const { watch, control, setValue, handleSubmit } = useForm<SendFormViewType>();
	const navigate = useNavigate();

	const targetYm = watch('targetYm');
	const templateType = watch('templateType');
	const reservedTime = watch('reservedTime');
	const sendType = watch('sendType');

	const { data: myAccountInfo } = useMyAccountInfo();
	const [sendTargets, setSendTargets] = useState<SendTarget[]>([]);
	const { data: templateList } = useTemplateList();
	const { mutate: sendMessage, isLoading: isMessageSending } = useSendMessage(
		(client, returnData, variables) => {
			if (!returnData?.sendId) {
				Toast.error('발송 요청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
				return;
			}
			// 예약발송인 경우
			if (variables.sendReservationDate) {
				Toast.success('정상적으로 예약 발송을 등록 하였습니다.');
				navigate(RouterPath.sendHistoryTab().reserve.key);
			} else {
				navigate(RouterPath.sendHistoryTab().message.key);
				Toast.success('정상적으로 발송 요청을 하였습니다.');
			}
		},
	);

	const templateListOptions = useMemo(
		() =>
			(templateList || []).map((item) => ({
				label: item.sendTemplateTitle,
				value: item.sendTemplateId,
				data: item,
			})),
		[templateList],
	);

	const { showDialog, hideDialog } = useDialog();

	const onSubmit = (data: SendFormViewType) => {
		if (isMessageSending) return;
		const recipients =
			sendTargets?.filter((item) => item?.type === 'recipient').map((item) => item?.id) ?? [];
		const employees =
			sendTargets?.filter((item) => item?.type === 'employee').map((item) => item?.id) ?? [];
		const isSchedule = !!data.sendType[0]?.value;
		const param: SendMessageRequest & { [key: string]: any } = {
			isSchedule,
			sendTemplateId: data.templateType.data?.sendTemplateId,
			centerId: myAccountInfo?.centerId,
			targetYm: dayjs(data.targetYm).format('YYYYMM'),
			messageTitleNm: data.messageTitleNm,
			messageDesc: data.messageDesc,
			receivers: {
				employees,
				recipients,
			},
		};

		const totalReceivers = (employees?.length || 0) + (recipients?.length || 0);
		const templateNm = data.templateType.label;
		let title = '';
		let content = '';
		let buttonText = '';

		if (isSchedule) {
			param.sendReservationDate = `${dayjs(data.reservedDate).format('YYYY-MM-DD')} ${
				data.reservedTime
			}:00`;
			title = '예약 발송을 하시겠습니까?';
			content = `총 ${totalReceivers}명에게 ${dayjs(data.targetYm).format(
				'YYYY년 M월',
			)} ${templateNm}를 예약 발송을 할 수 있도록 요청합니다.`;
			buttonText = '예약 발송';
		} else {
			title = '즉시 발송을 하시겠습니까?';
			content = `총 ${totalReceivers}명에게 ${templateNm}를 지금 발송할 수 있도록 요청합니다.`;
			buttonText = '발송';
		}

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title={title}
				content={content}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: buttonText,
					successCallback: () => {
						hideDialog();
						sendMessage(param);
					},
				}}
				hideDialog={hideDialog}
			/>
		));
	};

	const renderSearchUserInfo = (target: SendTarget) => (
		<S.TargetContainer>
			<S.TargetName>{target?.name}</S.TargetName>
			<S.TargetDescription>{`${target?.birthDate}${
				target.description ? ` ・ ${target.description}` : ''
			}`}</S.TargetDescription>
		</S.TargetContainer>
	);

	const handleApply = (targets: SendTarget[]) => {
		setSendTargets(targets);
		hideDialog();
	};

	const showSelectModal = () => {
		showDialog(() => (
			<SendTargetDialog
				sendTargets={sendTargets}
				onApply={handleApply}
				targetInfo={{
					templateId: templateType ? String(templateType.data?.sendTemplateId) : undefined,
					targetYm: targetYm ? dayjs(targetYm).format('YYYYMM') : undefined,
				}}
			/>
		));
	};

	const handleDeleteTarget = (target: SendTarget) => () => {
		const newSendTargets =
			sendTargets?.filter((item) => !(item.id === target.id && item.type === target.type)) ?? [];
		setSendTargets(newSendTargets);
	};

	const handleTemplateType = (callback: any) => {
		showDialog(() => (
			<DefaultDialog
				title='문서 유형을 변경하십니까?'
				content={
					<>
						수신자가 선택된 상태에서 문서 유형을 변경할 경우 수신자의
						<br />
						리스트는 초기화 됩니다. 변경을 진행 하시겠습니까?
					</>
				}
				cancelOption={{
					text: '취소',
					callback: hideDialog,
				}}
				successOption={{
					text: '변경',
					successCallback() {
						setSendTargets([]);
						callback();
						hideDialog();
					},
				}}
				hideDialog={hideDialog}
			/>
		));
	};

	const isValid = useMemo(() => {
		if (sendType?.[0]?.value && templateType) {
			if (templateType?.value === 'CMN009.999') {
				if (!targetYm && reservedTime && reservedTime?.length === 5 && sendTargets?.length) {
					return true;
				}
			} else if (targetYm && reservedTime && reservedTime?.length === 5 && sendTargets?.length) {
				return true;
			}
			return false;
		}
		if (templateType?.value === 'CMN009.999') {
			if (!targetYm && sendTargets?.length) {
				return true;
			}
			return false;
		}
		if (targetYm && sendTargets?.length) {
			return true;
		}

		return false;
	}, [targetYm, templateType, reservedTime, sendType, sendTargets]);

	useEffect(() => {
		// 문서유형 기타 선택시 기존 제목, 내용 초기화
		if (templateType?.value === 'CMN009.999') {
			setValue('targetYm', undefined);
			setValue('messageTitleNm', '');
			setValue('messageDesc', '');
		} else {
			const targetYmDate = targetYm ? dayjs(targetYm).format('YYYY년 M월') : '';
			const titleName = templateType?.data?.messageTitleNm || '';
			const replacedTitleName = titleName.replace(/{targetYm}/g, targetYmDate);
			const documentTitle = targetYmDate ? replacedTitleName : titleName;

			setValue('messageTitleNm', documentTitle);
			setValue('messageDesc', templateType?.data?.messageDesc?.replaceAll('\\n', '\n') || '');
		}
	}, [templateType, targetYm]);

	const isReceiverSearchDisabled = useMemo(() => {
		if (templateType?.value === 'CMN009.999') {
			return false;
		}
		if (templateType?.value !== 'CMN009.999' && targetYm) {
			return false;
		}
		return true;
	}, [targetYm, templateType]);

	console.log('templateListOptions', templateListOptions);

	return (
		<S.Container onSubmit={handleSubmit(onSubmit)}>
			<S.ContentsContainer>
				<S.WriteFormSection>
					<S.Title>신규 발송</S.Title>
					<S.FormSection>
						<S.FormLeftSection>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='발송 형식' isRequired>
										<CRCheckBoxGroup
											style={{
												width: 'fit-content',
											}}
											checkType='single'
											type='radio'
											onChange={onChange}
											appearanceType='button'
											gap={0.8}
											value={value}
											options={[
												{
													label: '즉시 발송',
													value: false,
												},
												{
													label: '예약 발송',
													value: true,
												},
											]}
										/>
									</CRInputLabel>
								)}
								name='sendType'
								control={control}
							/>
							{sendType?.[0].value && (
								<S.InputContainer>
									<Controller
										render={({
											field: { onChange, onBlur, value, ref },
											formState: { errors },
										}) => (
											<CRInput.DatePicker
												minDate={new Date()}
												placeholder='예약 날짜 선택'
												onChangeValue={onChange}
												value={value}
											/>
										)}
										control={control}
										name='reservedDate'
									/>
									<Controller
										render={({
											field: { onChange, onBlur, value, ref },
											formState: { errors },
										}) => (
											<>
												<CRInput.Time
													minTime='09:00'
													maxTime='18:00'
													maxLength={5}
													placeholder='시간 입력'
													value={value}
													onChange={onChange}
												/>
												<S.InputDescription>
													09:00~18:00까지만 입력할 수 있습니다.
												</S.InputDescription>
											</>
										)}
										control={control}
										name='reservedTime'
									/>
								</S.InputContainer>
							)}
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='문서유형' isRequired>
										<CRInput.Selector
											disabled={!sendType}
											placeholder='문서 유형 선택'
											currentValue={value}
											onChangeValue={(option) => {
												if (sendTargets?.length && templateType) {
													handleTemplateType(() => {
														setValue('targetYm', undefined);
														onChange(option);
													});
												} else {
													setValue('targetYm', undefined);
													onChange(option);
												}
											}}
											items={templateListOptions}
										/>
									</CRInputLabel>
								)}
								name='templateType'
								control={control}
							/>
							{!!templateType && templateType.value !== 'CMN009.999' && (
								<Controller
									render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
										<CRInput.YearMonthPicker
											currentValue={value}
											placeholder='년월 선택'
											onChangeValue={(option) => {
												if (sendTargets?.length && templateType) {
													handleTemplateType(() => onChange(option));
												} else {
													onChange(option);
												}
											}}
										/>
									)}
									control={control}
									name='targetYm'
								/>
							)}
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel
										label='제목'
										isRequired
										renderRightAddon={`${value?.length ?? 0}/32`}>
										<CRInput.Default
											disabled={!(templateType?.value === 'CMN009.999')}
											placeholder='제목 입력'
											value={value}
											onChange={onChange}
											maxLength={32}
										/>
									</CRInputLabel>
								)}
								name='messageTitleNm'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, value, ref }, formState: { errors } }) => (
									<CRInputLabel
										label='내용'
										isRequired
										renderRightAddon={`${value?.length ?? 0}/1,000`}>
										<CRInput.TextArea
											fixedHeight
											// 윈도우OS에 맞춘 높이값. 수정시에 꼭 윈도우에서 확인해주세요.
											height={sendType?.[0]?.value === true ? '400px' : '282px'}
											disabled={!(templateType?.value === 'CMN009.999')}
											placeholder='내용 입력'
											maxLength={1000}
											value={value}
											onChange={onChange}
										/>
									</CRInputLabel>
								)}
								name='messageDesc'
								control={control}
							/>
						</S.FormLeftSection>
						<S.FormRightSection>
							<CRInputLabel label='수신자' isRequired>
								<CRInput.Search
									placeholder='수신자 선택'
									onClick={showSelectModal}
									disabled={isReceiverSearchDisabled}
								/>
							</CRInputLabel>
							<S.UserSelector>
								{sendTargets?.length > 0 ? (
									<>
										<S.UserSelectInfo>
											<span>선택</span>
											<span>총 {sendTargets?.length}명</span>
										</S.UserSelectInfo>
										<S.UserList>
											{sendTargets?.map((item) => (
												<S.UserListItem key={item.id} $isSelected={false}>
													{renderSearchUserInfo(item)}
													<S.DeleteIcon
														src={Assets.icon.close}
														onClick={handleDeleteTarget(item)}
													/>
												</S.UserListItem>
											))}
										</S.UserList>
									</>
								) : (
									<S.TargetEmpty>
										먼저 문서 유형 선택 후<br />
										수신자를 선택해 주시길 바랍니다.
									</S.TargetEmpty>
								)}
							</S.UserSelector>
						</S.FormRightSection>
					</S.FormSection>
				</S.WriteFormSection>
			</S.ContentsContainer>
			<S.BottomMenuContainer>
				{isMessageSending ? (
					<CRButton.Default disabled buttonType='submit'>
						<FlexContainer gap='1.2rem' align='center'>
							<CRSpinner floatMode={false} />
							발송중
						</FlexContainer>
					</CRButton.Default>
				) : (
					<CRButton.Default disabled={!isValid} buttonType='submit'>
						발송
					</CRButton.Default>
				)}
			</S.BottomMenuContainer>
		</S.Container>
	);
}

export default SendMessageTab;
