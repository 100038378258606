import dayjs from 'dayjs';
import {
	displayBirthDay,
	displayPhoneNumber,
	displayRdsNumber,
	displayRsdnNoToBirthDate,
} from 'lib';
import { MyAccountInfoDTO } from 'types/api/auth';
import { CenterDTO } from 'types/api/center';
import {
	EDocAdmissionUseRequestDTO,
	EDocPledgeDTO,
	EDocResignDTO,
	IssueEDocumentRequest,
} from 'types/api/eDoc';
import { EmployeeDTO } from 'types/api/employee';
import {
	EmploymentCertificationFormTypes,
	EDocWithOutSignIssueItem,
	PledgeFormTypes,
	EDocPledgeIssueItem,
	OtherBankBookFormTypes,
	EDocOtherBankBookIssueItem,
	EDocResignIssueItem,
	ResignFormTypes,
	AdmissionUseRequestFormTypes,
	IssueAdmissionUseRequestIssueItem,
	IssueAdmissionUseRequestService,
} from 'types/view/eDoc';
import { Recipient } from 'types/view/recipient';

export const eDocwithOutSignIssueFormAdapter = (
	formData: EmploymentCertificationFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
	stampImageData = '',
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}
	const {
		data: {
			data: { employee },
		},
	} = formData;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: employee.map((item, index) => {
			const data: EDocWithOutSignIssueItem = {
				companyNm: item.centerNm,
				assignTaskNm: item?.dutyCdNm,
				workStartEndDate: item?.contractPeriod,
				issueDescNm: formData.issueReason[0].label || formData.issueReasonEtcDescription,
			};
			if (index === 0) {
				data.nm = currentEmployee.korMemberNm;
				data.birthDt = displayBirthDay(currentEmployee.birthDt);
				data.workStartDate = item?.contractStartDate
					? dayjs(item?.contractStartDate).format('YYYY-MM-DD')
					: '';
			}
			if (index === employee.length - 1) {
				data.year = String(dayjs().get('year'));
				data.month = String(dayjs().get('month') + 1);
				data.day = String(dayjs().get('date'));
				data.corpNm = item.centerNm;
				data.corpStampImageData = stampImageData;
			}

			return data;
		}),
	};

	const result = {
		edocTitle: `${currentEmployee.korMemberNm}님 ${formData.data.edocNm}`,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		edocReceivePhoneNos: [String(currentEmployee.mobilePhoneNo)],
		edocReceiverIds: [currentEmployee.employeeId],
		edocReceiverNms: [String(currentEmployee.korMemberNm)],
	} as IssueEDocumentRequest;

	return result;
};

export const eDocPledgeIssueFormAdapter = (
	formData: PledgeFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocPledgeDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(employee.accidentInsuranceAcqDate).format('YYYY.MM.DD'),
				centerNm: employee.centerNm,
				recipientNm: formData.recipient.label,
				content: formData.pledgeContent,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
			} as EDocPledgeIssueItem,
		],
	};

	const result = {
		edocTitle: `${currentEmployee.korMemberNm}님 ${formData.data.edocNm}`,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
	} as IssueEDocumentRequest;

	return result;
};

export const eDocOtherBankBookIssueFormAdapter = (
	formData: OtherBankBookFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocPledgeDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(employee.accidentInsuranceAcqDate).format('YYYY.MM.DD'),
				bankNm: formData.bank.label,
				accountNumber: formData.accountNumber,
				accountNm: formData.name,
				accountBirth: displayBirthDay(formData.birthDt),
				accountPhone: displayPhoneNumber(formData.phoneNumber),
				relationshipType: formData.relationEtc || formData.relation.label,
				reason: formData.reason,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
			} as EDocOtherBankBookIssueItem,
		],
	};

	const result = {
		edocTitle: `${currentEmployee.korMemberNm}님 ${formData.data.edocNm}`,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
	} as IssueEDocumentRequest;

	return result;
};

export const eDocResignIssueFormAdapter = (
	formData: ResignFormTypes,
	currentEmployee: EmployeeDTO,
	currentSender?: MyAccountInfoDTO,
) => {
	if (!formData.data?.data.employee || !currentSender || !currentEmployee) {
		return undefined;
	}

	const { employee } = (formData.data as unknown as EDocResignDTO).data;

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				nm: employee.korMemberNm,
				signNm: '',
				centerNm: employee.centerNm,
				birthDt: displayBirthDay(employee.birthDt),
				workStartDate: dayjs(employee.accidentInsuranceAcqDate).format('YYYY.MM.DD'),
				leaveHopeYear: dayjs(formData.resignDate).format('YYYY'),
				leaveHopeMonth: dayjs(formData.resignDate).format('M'),
				leaveHopeDay: dayjs(formData.resignDate).format('D'),
				assignTaskNm: currentEmployee.dutyNms,
				leaveChk1: formData.resignType.value === 'CMN109.51' ? '1' : '',
				leaveChk2: formData.resignType.value === 'CMN109.52' ? '1' : '',
				leaveComCdId: formData.resignType.value,
				leaveDetailComCdId: formData.resignDetailType.value,
				year: String(dayjs().get('year')),
				month: String(dayjs().get('month') + 1),
				day: String(dayjs().get('date')),
				leaveYear: dayjs(formData.resignDate).format('YYYY'),
				leaveMonth: dayjs(formData.resignDate).format('M'),
				leaveDay: dayjs(formData.resignDate).format('D'),
				leaveReason: formData.reason || '',
			} as EDocResignIssueItem,
		],
	};

	const result = {
		edocTitle: `${currentEmployee.korMemberNm}님 ${formData.data.edocNm}`,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [employee.employeeId, currentSender.memberAccountId],
		signatoryNms: [employee.korMemberNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [employee.mobilePhoneNo || '', ''],
		signatoryBirthDts: [employee.birthDt || '', ''],
	} as IssueEDocumentRequest;

	return result;
};

// 입소이용신청서 발급
export const eDocAdmissionUseRequestFormAdapter = (
	formData: AdmissionUseRequestFormTypes,
	currentRecipient?: Recipient,
	currentSender?: MyAccountInfoDTO,
	currentCenter?: CenterDTO,
) => {
	if (!formData?.data || !currentSender || !currentRecipient || !currentCenter) {
		return undefined;
	}

	const {
		recipient: { recipient, guardians },
		youNm = '',
		center,
	} = (formData.data as unknown as EDocAdmissionUseRequestDTO).data;

	let serviceTotalAmt = 0;
	const service = formData.service.map((item, index) => {
		const serviceAmtOfMonth =
			item.serviceFee && item.serviceNumberOfMonth
				? Number(item.serviceFee) * Number(item.serviceNumberOfMonth)
				: 0;
		serviceTotalAmt += serviceAmtOfMonth;
		const serviceItem = {
			serviceType: item.serviceType?.label || '',
			serviceDesc: item.serviceDesc || '',
			serviceFee: item.serviceFee ? Number(item.serviceFee).toLocaleString() : '',
			serviceNumberOfMonth: item.serviceNumberOfMonth?.toLocaleString() || '',
			serviceAmtOfMonth: String(serviceAmtOfMonth.toLocaleString()),
			serviceCenterNm: currentSender.centerNm,
			serviceCenterMark: String(currentCenter.centerMark),
		} as IssueAdmissionUseRequestService & { [key: string]: string };

		if (index === 0) {
			serviceItem.recipientNm = formData.recipientNm || '';
			serviceItem.recipientBirthDt = displayRsdnNoToBirthDate(formData.recipientRsdnNo) || '';
			serviceItem.recipientType = '재가급여';
			serviceItem.recipientStartEndDate =
				`${dayjs(formData.startEndDate.start).format('YYYY.MM.DD')} ~ ${
					formData.startEndDate.end
						? dayjs(formData.startEndDate.end).format('YYYY.MM.DD')
						: dayjs().format('YYYY.MM.DD')
				}` || '';
			serviceItem.longTermNo = formData.longTermNo || '';
			serviceItem.longTermGradeNm = formData.longTermGradeCd?.label || '';
		}
		if (index === formData.service.length - 1) {
			// 마지막 급여 데이터 에 총 수가 추가
			serviceItem.serviceTotalAmt = `${serviceTotalAmt.toLocaleString()}원` || '';
		}

		return serviceItem;
	});

	const eFormData = {
		crfeNm: formData.data?.eformFileNm,
		params: [
			{
				newChk: formData.type?.value === '신규' ? '1' : '',
				renewalChk: formData.type?.value === '갱신' ? '1' : '',
				changeChk: formData.type?.value === '변경' ? '1' : '',
				terminationChk: formData.type?.value === '해지' ? '1' : '',
				consenterNm: currentSender?.userNm || '',
				signConsenterNm: '',
				consenterPhoneNo: displayPhoneNumber(currentSender?.workMobilePhoneNo) || '',
				consenterRelNm: '담당 사회복지사',
				consenterBirthDt: displayBirthDay(formData.birthDt) || '',
				consenterAddr: `${center?.centerZipCode ? `(${center?.centerZipCode}) ` : ''} ${
					center?.centerBasAddr || ''
				}${` ${formData?.data?.data?.center?.centerDetailAddr || ''}`}`,
				recipientNm: formData.recipientNm || '-',
				signRecipientNm: '',
				recipientBirthDt: displayBirthDay(recipient.birthDt) || '',
				recipientAddr: `${formData?.recipientBaseAddr || ''}${
					formData?.recipientDetailAddr ? `, ${formData?.recipientDetailAddr}` : ''
				}`,
				recipientPhoneNo: displayPhoneNumber(recipient.mobilePhoneNo) || '',
				rsdnNo: displayRdsNumber(formData.recipientRsdnNo) || '',
				longTermNo: formData.longTermNo || '-',
				longTermGradeNm: formData.longTermGradeCd?.label || '-',
				centerNm: currentRecipient.centerName || '-',
				gubunChk1: recipient.burdenRateCd === 'CMN043.10' ? '1' : '',
				gubunChk2: recipient.burdenRateCd !== 'CMN043.10' ? '1' : '',
				year: String(dayjs(formData.requestDate).get('year')),
				month: String(dayjs(formData.requestDate).get('month') + 1),
				day: String(dayjs(formData.requestDate).get('date')),
				youNm,
				service,
				welfare: [],
			} as IssueAdmissionUseRequestIssueItem,
		],
	};

	const mainGuardian = guardians.find((item) => item.mainGuardianYn);
	const result = {
		edocTitle: formData.data.edocNm,
		edocSenderId: currentSender.memberAccountId,
		edocSenderNm: currentSender.userNm,
		edocSenderPhoneNo: '',
		centerId: currentSender.centerId,
		paperTypeCd: formData?.data.paperTypeCd,
		edocParamValue: JSON.stringify(eFormData),
		signatoryIds: [recipient.recipientId, currentSender.memberAccountId],
		signatoryNms: [recipient.recipientNm || '', currentSender.userNm || ''],
		signatoryPhoneNos: [mainGuardian?.mobilePhoneNo || '', ''],
		signatoryBirthDts: [mainGuardian?.birthDt || '', ''],
		receiverTargetDivCd: 'CMN118.20',
	} as IssueEDocumentRequest;

	return result;
};
