import { endpoint } from 'lib/service/Api/endpoint';
import useCRMutation from '../base/useCRMutation';

export const useOpenEDocClient = useCRMutation(endpoint.openEDocClient.key, 'openEDocClient');

export const useConfirmEDocClient = useCRMutation(
	endpoint.confirmEDocClient.key,
	'confirmEDocClient',
);

export const useOpenEDocManager = useCRMutation(endpoint.openEDocManager.key, 'openEDocManager');

export const useConfirmEDocManager = useCRMutation(
	endpoint.confirmEDocManager.key,
	'confirmEDocManager',
);

export const useSaveRecordSheetsClient = useCRMutation(
	endpoint.saveRecordingSheetsClient.key,
	'saveRecordingSheetsClient',
);

export const useResendEDoc = useCRMutation(endpoint.resendEDoc.key, 'resendEDoc');
export const useDeletePaper = useCRMutation(endpoint.deletePaper.key, 'deletePaper');

export const useEFormFileName = useCRMutation(endpoint.getEFormFileName.key, 'getEFormFileName');

export const useExportEFormToPdf = useCRMutation(endpoint.exportEformToPdf.key, 'exportEformToPdf');

export const useConfirmRecipientEdocAdmissionUse = useCRMutation(
	endpoint.confirmRecipientEdocAdmissionUse.key,
	'confirmRecipientEdocAdmissionUse',
);
