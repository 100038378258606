/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-loop-func */

import dayjs from 'dayjs';

/* eslint-disable guard-for-in */
export type URLBreadCrumbConfig = {
	key: string;
	link: string;
	label: string;
	preConditions?: string[];
	excludeConditions?: string[];
	shouldFirst?: boolean;
};

const urlToBreadCrumbs: {
	[key: string]: URLBreadCrumbConfig;
} = {
	recipient: {
		key: 'recipient',
		link: '/recipient',
		label: '수급자',
	},
	employee: {
		key: 'employee',
		link: '/employee',
		label: '직원',
	},
	consultingDetail: {
		key: 'detail',
		link: 'detail',
		label: '상담상세',
		preConditions: ['consulting'],
	},
	consultingNew: {
		key: 'new',
		link: '/consulting/new',
		label: '신규 상담',
		preConditions: ['consulting'],
	},
	consultingEdit: {
		key: 'edit',
		link: '/consulting/edit',
		label: '상담수정',
		preConditions: ['consulting'],
	},
	employeeConsultingDetail: {
		key: 'detail',
		link: 'detail',
		label: '상담상세',
		preConditions: ['consulting'],
	},
	send: {
		key: 'send',
		link: 'send',
		label: '발송',
	},
	sendHistoryDetail: {
		key: 'detail',
		link: 'detail',
		label: '메세지 예약 발송 상세',
		preConditions: ['send', 'history'],
	},
	contractRecipient: {
		key: 'contract',
		link: 'contract',
		label: '신규계약',
		preConditions: ['recipient'],
	},
	integratedAssessmentFallInfo: {
		key: 'fall-info',
		link: 'fall-info',
		label: '낙상위험도',
		preConditions: ['recipient'],
	},
	integratedAssessmentBedsoreInfo: {
		key: 'bedsore-info',
		link: 'bedsore-info',
		label: '욕창위험도',
		preConditions: ['recipient'],
	},
	integratedAssessmentKmmseInfo: {
		key: 'kmmse-info',
		link: 'kmmse-info',
		label: '인지기능',
		preConditions: ['recipient'],
	},
	integratedAssessmentDesireInfo: {
		key: 'desire-info',
		link: 'desire-info',
		label: '욕구사정',
		preConditions: ['recipient'],
	},
	integratedAssessmentSalaryOfferPlanInfo: {
		key: 'salary-offer-plan-info',
		link: 'salary-offer-plan-info',
		label: '급여제공변경계획서',
		preConditions: ['recipient'],
	},
	employeeResign: {
		key: 'resign',
		link: 'resign',
		label: '퇴사',
		preConditions: ['employee'],
	},
	recipientResign: {
		key: 'resign',
		link: 'resign',
		label: '퇴소',
		preConditions: ['recipient'],
	},
	contract: {
		key: 'contract',
		link: 'contract',
		label: '계약 관리',
		shouldFirst: true,
	},
	contractRecipientBasic: {
		key: 'recipient-basic',
		link: 'recipient-basic',
		label: '신규 계약',
		preConditions: ['contract'],
		excludeConditions: ['recipient', 'renewal', 'change-employee-contract'],
	},
	contractService: {
		key: 'contract-service',
		link: 'contract-service',
		label: '신규 계약',
		preConditions: ['contract'],
		excludeConditions: ['recipient', 'renewal', 'change-employee-contract'],
	},
	contractManager: {
		key: 'manager',
		link: 'manager',
		label: '신규 계약',
		preConditions: ['contract'],
		excludeConditions: ['recipient', 'renewal', 'change-employee-contract'],
	},
	contractInspection: {
		key: 'contract-inspection',
		link: 'contract-inspection',
		label: '신규 계약',
		preConditions: ['contract'],
		excludeConditions: ['recipient'],
	},
	renewal: {
		key: 'contract-renewal',
		link: 'contract-renewal',
		label: '갱신',
		preConditions: ['service'],
	},
	contractRenewal: {
		key: 'contract-renewal',
		link: 'contract-renewal',
		label: '계약 관리',
		shouldFirst: true,
	},
	contractRenewalRecipientBasic: {
		key: 'recipient-basic',
		link: 'recipient-basic',
		label: '갱신',
		preConditions: ['contract-renewal'],
		excludeConditions: ['recipient', 'contract', 'change-employee-contract'],
	},
	contractRenewalService: {
		key: 'contract-service',
		link: 'contract-service',
		label: '갱신',
		preConditions: ['contract-renewal'],
		excludeConditions: ['recipient', 'contract', 'change-employee-contract'],
	},
	contractRenewalManager: {
		key: 'manager',
		link: 'manager',
		label: '갱신',
		preConditions: ['contract-renewal'],
		excludeConditions: ['recipient', 'contract', 'change-employee-contract'],
	},
	contractRenewalInspection: {
		key: 'contract-inspection',
		link: 'contract-inspection',
		label: '갱신',
		preConditions: ['contract-renewal'],
		excludeConditions: ['recipient'],
	},
	changeEmployeeContract: {
		key: 'contract-change-employee',
		link: 'contract-change-employee',
		label: '직원 변경',
		preConditions: ['service'],
	},
	contractChangeEmployee: {
		key: 'contract-change-employee',
		link: 'contract-change-employee',
		label: '계약 관리',
		shouldFirst: true,
	},
	contractChangeEmployeeRecipientBasic: {
		key: 'recipient-basic',
		link: 'recipient-basic',
		label: '직원 변경',
		preConditions: ['contract-change-employee'],
		excludeConditions: ['recipient', 'contract', 'renewal'],
	},
	contractChangeEmployeeService: {
		key: 'contract-service',
		link: 'contract-service',
		label: '직원 변경',
		preConditions: ['contract-change-employee'],
		excludeConditions: ['recipient', 'contract', 'renewal'],
	},
	contractChangeEmployeeManager: {
		key: 'manager',
		link: 'manager',
		label: '직원 변경',
		preConditions: ['contract-change-employee'],
		excludeConditions: ['recipient', 'contract', 'renewal'],
	},
	contractChangeEmployeeInspection: {
		key: 'contract-inspection',
		link: 'contract-inspection',
		label: '직원 변경',
		preConditions: ['contract-change-employee'],
		excludeConditions: ['recipient'],
	},
	insurance: {
		key: 'insurance',
		link: 'insurance',
		label: '4대 보험',
	},
	insuranceDetail: {
		key: 'detail',
		link: 'detail',
		label: '상세',
		preConditions: ['insurance'],
	},
	insuranceHistory: {
		key: 'history',
		link: 'history',
		label: '4대보험 이력',
		preConditions: ['insurance'],
	},
	insuranceRequest: {
		key: 'request',
		link: 'request',
		label: '신고',
		preConditions: ['insurance'],
	},
	workExecuteLog: {
		key: 'work-execute-log',
		link: 'work-execute-log',
		label: '업무수행일지',
		preConditions: ['recipient', 'work-execute'],
	},
	myCenter: {
		key: 'my-center',
		link: 'my-center',
		label: '내 센터',
	},
	user: {
		key: 'user',
		link: 'user',
		label: '계정',
		preConditions: ['my-center'],
	},
	document: {
		key: 'document',
		link: 'document',
		label: '문서',
		preConditions: ['my-center'],
	},
	information: {
		key: 'information',
		link: 'information',
		label: '센터 정보',
		preConditions: ['my-center'],
	},
	accountManagement: {
		key: 'account-management',
		link: 'account-management',
		label: '계좌 관리',
		preConditions: ['my-center'],
	},
	financeInformation: {
		key: 'finance-information',
		link: 'finance-information',
		label: '재무 정보',
		preConditions: ['my-center'],
	},
	addFinanceInformation: {
		key: 'new',
		link: 'new',
		label: '재무 정보 추가',
		preConditions: ['my-center', 'finance-information'],
	},
	updateFinanceInformation: {
		key: 'detail',
		link: 'detail',
		label: '재무 정보 상세',
		preConditions: ['my-center', 'finance-information'],
	},
	monitoring: {
		key: 'monitoring',
		link: 'monitoring',
		label: '일정 모니터링',
		preConditions: ['schedule'],
	},
	monitoringDetail: {
		key: 'detail',
		link: 'detail',
		label: '일정 모니터링 상세',
		preConditions: ['schedule', 'monitoring'],
	},
	workExecuteInspectionLog: {
		key: 'work-execute-inspection-log',
		link: 'work-execute-inspection-log',
		label: '업무수행일지',
		preConditions: ['esign'],
	},
	recipeintWorkExecuteInspectionLog: {
		key: 'work-execute-inspection-log',
		link: 'work-execute-inspection-log',
		label: '업무수행일지',
		preConditions: ['recipient'],
	},
};

/**
 * URL을 분석해 BreadCrumbs로 변환
 * @param path URL 주소
 */
export const parseURLToBreadCrumbs = (path: string) => {
	const depths = path.split('/').filter((item) => !!item);

	const breadCrumbs = depths.reduce(
		(accumulate: { link: string; label: string }[], depth, index) => {
			const configs = Object.values(urlToBreadCrumbs);
			const config = configs.find((config) => {
				const matched = config.key === depth;
				const satisfiedPreConditions = config?.preConditions
					? config?.preConditions.every((preCondition) => depths.includes(preCondition))
					: true;
				const satisfiedExcludeConditions = config?.excludeConditions
					? config?.excludeConditions.every(
							(excludeCondition) => !depths.includes(excludeCondition),
					  )
					: true;
				const satisfyShouldFirst = config.shouldFirst ? index === 0 : true;
				return (
					matched && satisfiedPreConditions && satisfyShouldFirst && satisfiedExcludeConditions
				);
			});

			if (config) return [...accumulate, config];

			return accumulate;
		},
		[] as { link: string; label: string }[],
	);

	return breadCrumbs;
};

export const unique = <T>(items: T[], func?: (item: T) => any): T[] => {
	const map = new Map();

	items.forEach((item) => {
		const key = func ? func(item) : item;
		if (!map.has(key)) map.set(key, item);
	});
	return Array.from(map.values()).filter((item) => !!item);
};

export const getValidParam = (params: { [key: string]: any }) => {
	const newParams: { [key: string]: any } = { ...params };
	Object.keys(newParams).forEach((key) => {
		if (
			newParams[key] === '' ||
			newParams[key] === null ||
			newParams[key] === undefined ||
			(Array.isArray(newParams[key]) && newParams[key].length === 0)
		) {
			delete newParams[key];
		}
	});
	return newParams;
};

export function removeFalsyValues(obj: { [key: string]: any }): { [key: string]: any } {
	const newObj: { [key: string]: any } = {};
	Object.keys(obj).forEach((key) => {
		if (obj[key]) {
			newObj[key] = obj[key];
		}
	});
	return newObj;
}

export function removeInternalValues(obj: { [key: string]: any }): { [key: string]: any } {
	const newObj: { [key: string]: any } = {};
	Object.keys(obj).forEach((key) => {
		if (!key.startsWith('_')) {
			newObj[key] = obj[key];
		}
	});
	return newObj;
}

export function queryStringToObject<T extends Record<string, string>>(queryString: string): T {
	const params = new URLSearchParams(queryString);
	const result: any = {};

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of params) {
		result[key] = value;
	}

	return result;
}

export function objectToQueryString(obj: { [k: string]: any }): string {
	const queryParams = new URLSearchParams();

	// eslint-disable-next-line no-restricted-syntax
	for (const key in obj) {
		if (obj[key]) {
			queryParams.append(key, obj[key]);
		}
	}

	return `?${queryParams.toString()}`;
}

// 배열처리 관련해서 만든 함수
// export function objectToQueryString(obj: Record<string, string>): string {
// 	if (Object.values(obj).length === 0) {
// 		return '';
// 	}
// 	let queryString = '?';
// 	for (const key in obj) {
// 		const value = obj[key];
// 		if (!value) continue;
// 		if (Array.isArray(value)) {
// 			value.forEach((val) => {
// 				queryString += `&${key}=${val}`;
// 			});
// 		} else {
// 			queryString += `&${key}=${value}`;
// 		}
// 	}

// 	return queryString.replace('?&', '?');
// }

export function rsdnNumberToBirthDay(rsdnNumber?: string) {
	if (!rsdnNumber || rsdnNumber?.length !== 13) return '';
	const prevNumber = rsdnNumber.substring(0, 6);
	// 주민등록번호 앞자리에서 연, 월, 일 추출
	const year = prevNumber.substring(0, 2);
	const month = prevNumber.substring(2, 4);
	const day = prevNumber.substring(4, 6);

	// 1900년대와 2000년대 구분
	const prefix = Number(year) < 20 ? '20' : '19';

	// 생년월일로 변환
	const birthday = prefix + year + month + day;

	return birthday;
}

export function calculateKoreanAge(birthDate: string, target?: string) {
	const targetDate = dayjs(target);
	const birthDateObject = dayjs(birthDate);

	let koreanAge = targetDate.year() - birthDateObject.year();

	// 현재 월, 일이 출생일보다 전이라면 1살 감소
	if (
		targetDate.month() < birthDateObject.month() ||
		(targetDate.month() === birthDateObject.month() && targetDate.date() < birthDateObject.date())
	) {
		koreanAge -= 1;
	}

	return koreanAge;
}

export function isLongTermGradeExpireSoon(endDate?: string) {
	if (!endDate) return false;
	return dayjs(endDate).isBefore(dayjs().add(90, 'day'));
}

export function calculateLongTermGradeExpireLeftDate(endDate?: string) {
	return dayjs(endDate).add(1, 'days').diff(dayjs(), 'days');
}

export function isForeigner(rsdnNo: string) {
	if (rsdnNo?.length !== 13 || !rsdnNo) return '-';

	return !!['5', '6', '7', '8'].includes(rsdnNo[6]);
}
