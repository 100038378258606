import React from 'react';
import CRButton from 'components/base/CRButton';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { WorkTime } from 'types/api/employee';
import { CheckOption } from 'components/base/Selections/type';
import { displayPhoneNumber } from 'lib';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import { EmployeeOtherCorpWorkFormViewType, WorkStateCd } from 'types/view/employee';
import { v4 } from 'uuid';
import { CRText } from 'components/base/CRText';
import * as S from './styles';

interface Props {
	items?: EmployeeOtherCorpWorkFormViewType[];
	options: {
		day: CheckOption[];
	};
	onChangeMode: () => void;
}

export default function OtherCorpWorkView({
	items,
	options,
	onChangeMode,
}: Props): React.ReactElement {
	const { employeeDetailData } = useEmployeePage();

	const renderServiceTime = (workTime: WorkTime) => {
		const dateString = workTime.days
			?.map((item) => options?.day?.find((code) => code.value === item.otherCorpWorkDayCd)?.label)
			.join(', ');
		const startTimeString = `${workTime.otherCorpWorkStartTime.slice(
			0,
			2,
		)}:${workTime.otherCorpWorkStartTime.slice(2, 4)}`;
		const endTimeString = `${workTime.otherCorpWorkEndTime.slice(
			0,
			2,
		)}:${workTime.otherCorpWorkEndTime.slice(2, 4)}`;

		return `${dateString} ${startTimeString}~${endTimeString}`;
	};

	const isResign = employeeDetailData?.workStateCd === WorkStateCd.퇴사;

	return (
		<S.Container>
			<S.Label>
				타근무
				{!isResign && (
					<S.ButtonContainer>
						<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={onChangeMode}>
							편집
						</CRButton.Default>
					</S.ButtonContainer>
				)}
			</S.Label>
			{items && items?.length > 0 ? (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{items?.map((work, index) => (
						<S.TableContainer key={work.otherCorpWorkId}>
							<S.TableLabel>{`타근무 ${String(index + 1).padStart(2, '0')}`}</S.TableLabel>
							<S.Table $isDisabled={isResign}>
								<S.TableRow>
									<S.TableLabelColumn>유형</S.TableLabelColumn>
									<S.TableValueColumn>
										<CRCheckBoxGroup
											checkType='single'
											type='radio'
											gap={0.8}
											value={work?.personalBusinessOwnerYn}
											options={[
												{
													label: '근로자',
													value: false,
													disabled: true,
												},
												{
													label: '사업자',
													value: true,
													disabled: true,
												},
											]}
										/>
									</S.TableValueColumn>
									<S.TableLabelColumn
										rowSpan={3}
										style={{
											verticalAlign: 'top',
										}}>
										비고
									</S.TableLabelColumn>
									<S.TableValueColumn
										rowSpan={3}
										style={{
											verticalAlign: 'top',
										}}>
										{work?.remark || '-'}
									</S.TableValueColumn>
								</S.TableRow>

								<S.TableRow>
									<S.TableLabelColumn>상호명</S.TableLabelColumn>
									<S.TableValueColumn>{work.otherCorpNm || '-'}</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn>전화번호</S.TableLabelColumn>
									<S.TableValueColumn>
										{work.otherCorpPhoneNo ? displayPhoneNumber(work.otherCorpPhoneNo) : '-'}
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn>근무일시</S.TableLabelColumn>
									<S.TableValueColumn colSpan={3}>
										{work?.times
											?.filter(
												(item) =>
													item.days?.length &&
													item.otherCorpWorkStartTime &&
													item.otherCorpWorkEndTime,
											)
											?.map((item) => (
												<S.ServiceTimeText key={v4()}>{renderServiceTime(item)} </S.ServiceTimeText>
											)) || '-'}
									</S.TableValueColumn>
								</S.TableRow>
							</S.Table>
						</S.TableContainer>
					))}
				</>
			) : (
				<S.EmptyContainer>
					<CRText typography='label' color='gray60' text='타근무가 없습니다.' />
				</S.EmptyContainer>
			)}
		</S.Container>
	);
}
