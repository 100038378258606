import CRTableHeader from 'components/base/CRTableHeader';
import { CheckOption } from 'components/base/Selections/type';
import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { Filter } from 'types/view/filter';
import {
	useESignHistoryFilter,
	useMessageTabESignHistory,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { MessageTabESignHistoryDTO } from 'types/api/send';
import EmployeeESignTable from 'components/domain/table/EmployeeESignTable';
import { ESignSendHistoryDetailDialog } from 'components/domain/dialog/ESignSendHistoryDetailDialog';
import useDialog from 'lib/hook/util/useDialog';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import * as S from './styles';

export default function ESignMessageTab() {
	const { showDialog } = useDialog();
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState<string>('');
	const { currentRecipient } = useRecipientPage();
	const { data: myAccountInfo } = useMyAccountInfo();

	const [currentFilter, setCurrentFilter] = useState<{
		paperTypeFilter: CheckOption[];
		signStatusFilter: CheckOption[];
	}>({
		paperTypeFilter: [],
		signStatusFilter: [],
	});

	const { data: eSignHistoryFilter } = useESignHistoryFilter();

	const signStatusFilter = useMemo(
		() =>
			({
				key: 'signStatusFilter',
				type: 'multi',
				options: (eSignHistoryFilter?.lastEsignStateCds ?? [])
					.filter((item) => item.comCdGroupNm === 'CMN171')
					.map((item) => ({
						label: item.korComCdAliasNm,
						value: item.comCdId,
					})),
				placeholder: '상태',
			} as Filter<CheckOption>),
		[eSignHistoryFilter?.lastEsignStateCds],
	);

	const paperTypeFilter = useMemo(
		() =>
			({
				key: 'paperTypeFilter',
				type: 'multi',
				options: (eSignHistoryFilter?.paperTypeCds ?? []).map((item) => ({
					label: item.korComCdAliasNm,
					value: item.comCdId,
				})),
				placeholder: '템플릿',
			} as Filter<CheckOption>),
		[eSignHistoryFilter?.paperTypeCds],
	);

	const { data: reservationHistory, isLoading } = useMessageTabESignHistory({
		type: 'recipients',
		id: currentRecipient?.recipientId,
		params: {
			paperTypeCds: currentFilter.paperTypeFilter?.map((item) => item.value).join(', '),
			lastEsignStateCds: currentFilter.signStatusFilter?.map((item) => item.value).join(', '),
		},
	});

	const eSignHistoryData = useMemo(
		() =>
			(reservationHistory || []).filter((item) =>
				item.edocTitle.toLowerCase().includes(searchValue.toLowerCase()),
			),
		[reservationHistory, searchValue],
	);

	const handleSearchKeyword = (value: string) => setSearchValue(value);

	const handleClickRow = (item: MessageTabESignHistoryDTO) => {
		showDialog(() => (
			<ESignSendHistoryDetailDialog
				centerId={myAccountInfo?.centerId}
				edocSendId={item.edocSendId}
				navigate={navigate}
			/>
		));
	};

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				filters={[signStatusFilter, paperTypeFilter]}
				placeholder='제목으로 검색'
				searchValue={searchValue}
				onChangeSearchValue={handleSearchKeyword}
			/>
			<S.TableContainer>
				<EmployeeESignTable
					isLoading={isLoading}
					items={eSignHistoryData}
					onClickRow={handleClickRow}
				/>
			</S.TableContainer>
		</S.Container>
	);
}
