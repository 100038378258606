import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 2.4rem 1.6rem;
	gap: 1.6rem;

	& > * {
		user-select: auto;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const ReceiverContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem;
	border-radius: ${(props) => props.theme.radius.radius01};
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;
