import React, { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import TaskLayout from 'components/domain/layout/TaskLayout';
import CRScrollSpy from 'components/base/CRScrollSpy';
import CRPageHeader from 'components/base/CRPageHeader';
import CRScrollSpyContainer from 'components/base/CRScrollSpyContainer';
import CRPageFooter from 'components/base/CRPageFooter';
import RouterPath from 'common/router';
import useFullScreen from 'lib/hook/util/useFullScreen';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';

import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';
import * as S from './styles';
import { generateWorkExecuteLogScrollSpy } from './constant';

function WorkExecuteInspectionLogDetailPage(): React.ReactElement {
	const { setCustomBackHandler } = useGlobalLayout();
	const { updateWorkExecuteInspectionLog, form } = useWorkExecuteInspectionLog();
	const params = useParams<{ id?: string; detailId?: string }>();
	const navigate = useNavigate();
	const location = useLocation();

	const LeftSideComponent = useMemo(() => {
		const scrollSpySections = generateWorkExecuteLogScrollSpy(navigate, form);
		const splitPathName = location.pathname.split('/');
		const lastPath = splitPathName[splitPathName.length - 1];
		return (
			<S.LeftSideComponentContainer>
				<CRScrollSpy currentKey={lastPath + location.hash} sections={scrollSpySections} />
			</S.LeftSideComponentContainer>
		);
	}, [location, navigate, form.watch()]);

	const title = useMemo(() => {
		if (location.pathname.includes('work-execute-log')) return '업무수행일지';
		return '';
	}, [location.pathname]);

	const goBackPage = () => {
		if (location.pathname.includes('recipient')) {
			navigate(
				`${RouterPath.recipient().tab.key}/${params.id}/${
					RouterPath.recipient().message.uniqueKey
				}`,
				{
					replace: true,
				},
			);
		} else {
			navigate(`${RouterPath.sendHistoryTab().esign.key}`, {
				replace: true,
			});
		}
	};

	const handleClickCancel = useCallback(() => {
		goBackPage();
	}, []);

	useFullScreen();

	useEffect(() => {
		setCustomBackHandler(() => goBackPage);
		return () => {
			setCustomBackHandler(undefined);
		};
	}, [handleClickCancel]);

	return (
		<TaskLayout LeftSideComponent={LeftSideComponent}>
			<S.MainComponentContainer $hasTab={false}>
				<CRScrollSpyContainer offset={0}>
					<S.ContentContainer>
						<S.HeaderComponentContainer>
							<CRPageHeader headerTitle={title} />
						</S.HeaderComponentContainer>
						<Outlet />
					</S.ContentContainer>
				</CRScrollSpyContainer>
				<S.FooterContainer>
					<S.FooterContentContainer>
						<CRPageFooter
							rightButtons={[
								{
									palette: 'gray',
									buttonType: 'button',
									type: 'text',
									onClick: handleClickCancel,
									children: '취소',
								},
								{
									palette: 'primary',
									buttonType: 'button',
									type: 'filled',
									onClick: updateWorkExecuteInspectionLog,
									children: '검수 완료',
								},
							]}
						/>
					</S.FooterContentContainer>
				</S.FooterContainer>
			</S.MainComponentContainer>
		</TaskLayout>
	);
}

export default React.memo(WorkExecuteInspectionLogDetailPage);
