import React from 'react';
import { Controller } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';

import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import CRInput from 'components/base/CRInput';
import CRStatus from 'components/base/CRStatus';
import { v4 } from 'uuid';
import { ErrorMessage } from 'components/base/CRInputMessage/styles';
import CRIcon from 'components/base/CRIcon';
import Assets from 'assets';
import * as S from './styles';
import { VISIT_STATE_STATUS_CONFIG, salaryOfferVisitOption } from './constant';

interface Props {
	disabled: boolean;
}

function WorkExecuteLogVisitForm({ disabled }: Props): React.ReactElement {
	const { commonCodes, form } = useWorkExecuteInspectionLog();

	const statusCode = commonCodes?.CMN184?.map((item) => ({
		label: <CRStatus options={VISIT_STATE_STATUS_CONFIG}>{item.value}</CRStatus>,
		value: item.value,
	}));

	return (
		<S.Container>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='상태' type='left'>
						<CRInput.Selector
							disabled={disabled}
							onChangeValue={onChange}
							currentValue={value}
							items={statusCode}
							placeholder='방문 상태 선택'
						/>
					</CRInputLabel>
				)}
				name='workExecuteLogStateCd'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='방문(상담)일자' type='left'>
						<CRInput.DatePicker
							disabled
							placeholder='0000.00.00'
							onChangeValue={onChange}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='visitDt'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<>
						<CRInputLabel label='방문(상담)시간' type='left' isRequired>
							<CRInput.DayTimePicker
								disabled={disabled}
								timeAlign='left'
								startPlaceHolder='00:00'
								endPlaceHolder='00:00'
								iconFill
								showDays={false}
								currentValue={{
									id: v4(),
									days: [],
									startTime: value?.startTime || '',
									endTime: value?.endTime || '',
								}}
								onChange={(e) => {
									onChange(e);
								}}
							/>
						</CRInputLabel>
						{form.formState.errors?.visitTime?.message && (
							<ErrorMessage>{form.formState.errors.visitTime?.message}</ErrorMessage>
						)}
					</>
				)}
				name='visitTime'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='급여 종류' type='left'>
						<CRCheckBoxGroup
							disabled
							direction='col'
							onChange={onChange}
							appearanceType='button'
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							options={commonCodes?.serviceTypeOption || []}
						/>
					</CRInputLabel>
				)}
				name='serviceTypeCd'
				control={form?.control}
			/>
			<CRInputLabel
				label='요양보호사 방문 일정'
				isRequired={!!form.watch('salaryOfferVisitYn')?.[0]?.value}
				type='left'>
				<S.InputContainer>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.Default
								disabled={disabled}
								suffix={
									<S.IconContainer>
										<CRIcon src={Assets.icon.calendarToday} />
									</S.IconContainer>
								}
								placeholder='0000.00.00'
								onChange={onChange}
								value={value}
								maxLength={8}
							/>
						)}
						name='serviceDt'
						control={form?.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.DayTimePicker
								disabled={disabled}
								startPlaceHolder='00:00'
								endPlaceHolder='00:00'
								iconFill
								showDays={false}
								currentValue={{
									id: v4(),
									days: [],
									startTime: value?.startTime || '',
									endTime: value?.endTime || '',
								}}
								onChange={(e) => {
									onChange(e);
								}}
							/>
						)}
						name='serviceTime'
						control={form?.control}
					/>
				</S.InputContainer>
				{form.formState.errors?.visitTime?.message && (
					<ErrorMessage>{form.formState.errors.visitTime?.message}</ErrorMessage>
				)}
			</CRInputLabel>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='방문자' type='left'>
						<CRInput.Selector
							disabled
							onChangeValue={onChange}
							currentValue={value}
							items={commonCodes?.centerManagerOption}
							placeholder='방문자 선택'
						/>
					</CRInputLabel>
				)}
				name='visitor'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='시설장(관리책임자)' type='left'>
						<CRInput.Selector
							disabled={disabled}
							autoComplete
							items={commonCodes?.centerChiefOptions}
							currentValue={value}
							placeholder='센터장 선택'
							onChangeValue={onChange}
						/>
					</CRInputLabel>
				)}
				name='facilityManager'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel isRequired label='방문 장소' type='left'>
						<CRInput.Default
							disabled
							onChange={onChange}
							value={value}
							placeholder='방문 장소 입력'
							maxLength={50}
						/>
					</CRInputLabel>
				)}
				name='visitPlaceNm'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel label='급여제공 중 방문 여부' isRequired type='left'>
						<CRCheckBoxGroup
							disabled
							onChange={onChange}
							appearanceType='button'
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							options={salaryOfferVisitOption}
						/>
					</CRInputLabel>
				)}
				name='salaryOfferVisitYn'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, onBlur, value, ref } }) => {
					const isRequired = form.watch('salaryOfferVisitYn')?.[0]?.value === false;
					return (
						<CRInputLabel label='방문불가 사유' isRequired={isRequired} type='left'>
							<CRInput.TextArea
								fixedHeight
								ref={ref}
								maxLength={200}
								placeholder='방문불가 사유 입력'
								onChange={onChange}
								onBlur={onBlur}
								value={value}
							/>
						</CRInputLabel>
					);
				}}
				name='visitDenyDesc'
				control={form?.control}
			/>
		</S.Container>
	);
}

export default React.memo(WorkExecuteLogVisitForm);
