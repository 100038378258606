import { useContext } from 'react';

import { WorkExecuteInspectionLogContext } from 'lib/provider/workExecuteInspectionLog';

export default function useWorkExecuteInspectionLog() {
	const context = useContext(WorkExecuteInspectionLogContext);
	if (!context) {
		throw new Error(
			'useWorkExecuteInspectionLog must be used within a WorkExecuteInspectionLogContext',
		);
	}
	return context;
}
