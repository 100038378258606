import dayjs from 'dayjs';
import { CenterDTO } from 'types/api';
import { RecordingDetail } from 'types/api/schedule';
import { RecordingSheetItem } from 'types/view/schedule';

export enum ServiceTypeCd {
	'방문요양' = 'CMN006.10',
	'방문목욕' = 'CMN006.20',
	'방문간호' = 'CMN006.30',
}

const createRecordingSheetEDocParams = (
	data: RecordingSheetItem,
	myCenter: CenterDTO,
	crfeNm: string,
) => {
	if (data.serviceTypeCd === ServiceTypeCd.방문요양) {
		const getWeekNumber = (currentDate: dayjs.Dayjs) => {
			const startOfMonth = currentDate.startOf('month');
			const startOfWeek = startOfMonth.startOf('week');
			const diffDays = currentDate.diff(startOfWeek, 'day');
			const weekNumber = Math.ceil(diffDays / 7);

			return weekNumber;
		};

		const groupedWeekData: { [key: string]: RecordingDetail[] } = {};
		Object.keys(data.recordingDetails).forEach((date) => {
			const currentDate = dayjs(date);
			// 현재 달의 주차 계산
			const weekOfMonth = getWeekNumber(currentDate);

			// 그룹화된 데이터에 주차가 있는지 확인 후 추가
			if (!groupedWeekData[weekOfMonth]) {
				groupedWeekData[weekOfMonth] = [];
			}

			// 현재 날짜의 데이터를 해당 주차에 추가
			groupedWeekData[weekOfMonth].push(
				...data.recordingDetails[date].filter((detail) => detail.longTermNo === data.longTermNo),
			);
		});

		// 같은날짜가 여러개있을경우를 고려하여 데이터를 다시 분배
		const sortedWeekData: RecordingDetail[][] = Object.values(groupedWeekData)?.reduce(
			(result: RecordingDetail[][], currentArray: RecordingDetail[]) => {
				let tempArray: RecordingDetail[] = [...currentArray]; // 현재 배열을 복사하여 임시 배열 생성

				while (tempArray.length > 7) {
					result.push(tempArray.slice(0, 7)); // 7개 항목으로 잘라서 결과 배열에 추가
					tempArray = tempArray.slice(7); // 나머지 항목으로 임시 배열 업데이트
				}

				if (tempArray.length > 0) {
					result.push(tempArray); // 남은 항목이 있으면 결과 배열에 추가
				}

				return result;
			},
			[],
		);

		const params = sortedWeekData?.map((items) => {
			const param: { [key: string]: string } = {
				recipientNm: data.recipientNm || '',
				centerNm: myCenter.centerNm || '',
				centerMark: myCenter.centerMark || '',
				recipientBirthDt: data.recipientBirthDt
					? dayjs(data.recipientBirthDt).format('YYYY.MM.DD')
					: '', // 수급자 생년월일
				longTermNo: data.longTermNo || '',
				longTermGradeNm: data.longTermGradeNm || '',
				year: data.serviceYm?.slice(0, 4),
			};
			items.forEach((item, index) => {
				const startTime =
					item.rfidStart && item.rfidEnd
						? item.rfidStart?.split(' ')?.[1]?.slice(0, 5)
						: item.planStart?.split(' ')?.[1]?.slice(0, 5);

				const endTime =
					item.rfidStart && item.rfidEnd
						? item.rfidEnd?.split(' ')?.[1]?.slice(0, 5)
						: item.planEnd?.split(' ')?.[1]?.slice(0, 5);
				param[`schedule${index + 1}Dt`] = `${item.serviceYmd.slice(4, 6)}/${item.serviceYmd.slice(
					6,
				)}`;
				param[`schedule${index + 1}TotalMinute`] = String(item.rfidTotalMin ?? item.planTotalMin);
				param[`schedule${index + 1}StartTime`] = startTime || '';
				param[`schedule${index + 1}EndTime`] = endTime || '';
				param[`schedule${index + 1}Chk1`] = '';
				param[`schedule${index + 1}Chk2`] = '';
				param[`schedule${index + 1}Chk3`] = '';
				param[`schedule${index + 1}Chk4`] = '';
				param[`schedule${index + 1}Chk5`] = '';
				param[`schedule${index + 1}Chk6`] = '';
				param[`schedule${index + 1}Minute1`] = '';
				param[`schedule${index + 1}Minute2`] = '';
				param[`schedule${index + 1}Minute3`] = '';
				param[`schedule${index + 1}Minute4`] = '';
				param[`schedule${index + 1}Minute5`] = '';
				param[`schedule${index + 1}Chk7`] = '';
				param[`schedule${index + 1}Chk8`] = '';
				param[`schedule${index + 1}Minute6`] = '';
				param[`schedule${index + 1}Chk9_1`] = '';
				param[`schedule${index + 1}Chk9_2`] = '';
				param[`schedule${index + 1}Chk9_3`] = '';
				param[`schedule${index + 1}Chk10_1`] = '';
				param[`schedule${index + 1}Chk10_2`] = '';
				param[`schedule${index + 1}Chk10_3`] = '';
				param[`schedule${index + 1}Chk11_1`] = '';
				param[`schedule${index + 1}Chk11_2`] = '';
				param[`schedule${index + 1}Chk11_3`] = '';
				param[`schedule${index + 1}Mistake1`] = '';
				param[`schedule${index + 1}Mistake2`] = '';
				param[`schedule${index + 1}SpecialContent`] = '';
				param[`schedule${index + 1}WorkerNm`] = data.employeeNm || '';
				param[`schedule${index + 1}RecipientNm`] = data.recipientNm || '';
				param[`signSchedule${index + 1}WorkerNm`] = '';
				param[`signSchedule${index + 1}RecipientNm`] = '';
			});
			return param;
		});

		return {
			crfeNm,
			params,
		};
	}
	if (data.serviceTypeCd === 'CMN006.20') {
		const params: { [key: string]: string }[] = [];
		Object.values(data.recordingDetails).forEach((values) => {
			const startTime =
				values?.[0]?.rfidStart &&
				values?.[0]?.rfidEnd &&
				values?.[1]?.rfidStart &&
				values?.[1]?.rfidEnd &&
				dayjs(values?.[0]?.rfidStart).format('HH:mm') ===
					dayjs(values?.[1]?.rfidStart).format('HH:mm')
					? values[0].rfidStart
					: values[0].planStart;
			const endTime =
				values?.[0]?.rfidStart &&
				values?.[0]?.rfidEnd &&
				values?.[1]?.rfidStart &&
				values?.[1]?.rfidEnd &&
				dayjs(values?.[0]?.rfidEnd).format('HH:mm') === dayjs(values?.[1]?.rfidEnd).format('HH:mm')
					? values[0].rfidEnd
					: values[0].planEnd;
			const resultItem: { [key: string]: string } = {
				centerNm: myCenter.centerNm || '',
				centerMark: myCenter.centerMark || '',
				recipientNm: data.recipientNm || '',
				recipientBirthDt: data.recipientBirthDt
					? dayjs(data.recipientBirthDt).format('YYYY.MM.DD')
					: '', // 수급자 생년월일
				longTermNo: data.longTermNo || '',
				longTermGradeNm: data.longTermGradeNm || '', // 장기요양등급
				year: values?.[0]?.serviceYmd?.slice(0, 4) || '',
				month: values?.[0]?.serviceYmd?.slice(4, 6) || '',
				day: values?.[0]?.serviceYmd?.slice(6) || '',
				totalMinute: String(values?.[0]?.rfidTotalMin ?? values?.[0]?.planTotalMin) || '',
				startTime: startTime ? dayjs(startTime).format('HH:mm') : '',
				endTime: endTime ? dayjs(endTime).format('HH:mm') : '',
				carNumber: '',
				carChk1: '',
				carChk2: '',
				noCarChk1: '',
				noCarChk2: '',
				noCarChk3: '',
				provideChk1: '',
				provideChk2: '',
				provideChk3: '',
				beforeBathChk1: '',
				beforeBathChk2: '',
				beforeBathChk3: '',
				afterBathChk1: '',
				afterBathChk2: '',
				afterBathChk3: '',
				specialContent: '',
				worker1Nm: values?.[0]?.employeeNm || '',
				signWorker1Nm: '',
				worker2Nm: values?.[1]?.employeeNm || '',
				signWorker2Nm: '',
				signRecipientNm: '',
			};
			params.push(resultItem);
		});

		return {
			crfeNm,
			params,
		};
	}
	if (data.serviceTypeCd === 'CMN006.30') {
		const params: { [key: string]: string }[] = [];
		Object.values(data.recordingDetails).forEach((values) => {
			const matchingData = data?.recipientVisitNursingResponse?.find((data) => {
				const startDate = data.vniIssueDt;
				const endDate = data.vniEndDt;
				const targetDate = values?.[0].serviceYmd;
				const isBetweenDates = startDate <= targetDate && endDate >= targetDate;
				return isBetweenDates;
			});

			const startTime =
				values?.[0]?.rfidStart && values?.[0]?.rfidEnd
					? values[0].rfidStart?.split(' ')?.[1]?.slice(0, 5)
					: values[0].planStart?.split(' ')?.[1]?.slice(0, 5);
			const endTime =
				values?.[0]?.rfidStart && values?.[0]?.rfidEnd
					? values[0].rfidEnd?.split(' ')?.[1]?.slice(0, 5)
					: values[0].planEnd?.split(' ')?.[1]?.slice(0, 5);

			const resultItem: { [key: string]: string } = {
				centerNm: myCenter.centerNm,
				centerMark: myCenter.centerMark,
				recipientBirthDt: data.recipientBirthDt
					? dayjs(data.recipientBirthDt).format('YYYY.MM.DD')
					: '', // 수급자 생년월일
				longTermNo: data.longTermNo,
				longTermGradeNm: data.longTermGradeNm || '', // 장기요양등급
				vniMedicalInstitutionNm: matchingData?.vniMedicalInstitutionNm || '', // 의료기관명칭
				vniIssueDt: matchingData?.vniIssueDt
					? dayjs(matchingData.vniIssueDt).format('YYYY.MM.DD')
					: '', // 발급일
				vniValidDt: matchingData?.vniEndDt ? dayjs(matchingData.vniEndDt).format('YYYY.MM.DD') : '', // 유효기간
				vniDoctorLicenseNumber: matchingData?.vniDoctorLicenseNo || '', // 의사면허번호
				vniVisitNumber: matchingData?.vniVisitCnt || '', // 방문횟수
				year: values?.[0]?.serviceYmd?.slice(0, 4) || '',
				month: values?.[0]?.serviceYmd?.slice(4, 6) || '',
				day: values?.[0]?.serviceYmd?.slice(6) || '',
				startTime: startTime || '',
				endTime: endTime || '',
				totalMinute: String(values?.[0]?.rfidTotalMin ?? values?.[0]?.planTotalMin) || '',
				bloodPressureStart: '',
				bloodPressureEnd: '',
				pulse: '',
				temperature: '',
				healthcareMinute: '',
				healthcareChk1: '',
				healthcareChk2: '',
				healthcareChk3: '',
				healthcareChk4: '',
				nursingcareMinute: '',
				nursingcareChk1: '',
				nursingcareChk2: '',
				nursingcareChk3: '',
				nursingcareChk4: '',
				nursingcareChk5: '',
				nursingcareChk6: '',
				nursingcareChk7: '',
				nursingcareChk8: '',
				specialContent: '',
				workerNm: values?.[0]?.employeeNm || '',
				signWorkerNm: '',
				recipientNm: values?.[0]?.recipientNm || '',
				signRecipientNm: '',
			};
			params.push(resultItem);
		});

		return {
			crfeNm,
			params,
		};
	}
	return {
		crfeNm,
		params: [],
	};
};

export default createRecordingSheetEDocParams;
