import React, { ChangeEvent, useCallback, useMemo } from 'react';
import CRTable from 'components/base/CRTable';
import CRStatus from 'components/base/CRStatus';
import CRButton from 'components/base/CRButton';
import useDialog from 'lib/hook/util/useDialog';
import { RecordingSheetItem } from 'types/view/schedule';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { displayPhoneNumber } from 'lib';
import { RecordingDetail } from 'types/api/schedule';
import { SendRecordingSheetDialog } from 'components/domain/dialog/SendRecordingSheetDialog';
import RecordingSheetDetailDialog from 'components/domain/dialog/RecordingSheetDetailDialog';
import Colors from 'common/colors';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import { useCenterList, useEFormFileName, useMyAccountInfo } from 'lib/hook/react-query';
import dayjs from 'dayjs';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';

import { ESignWayDialog } from 'components/domain/dialog/ESignDialog';
import { ESignRequestForm } from 'types/view/eDoc';
import * as S from './styles';
import { RECORDING_SHEET_HEADER_CONFIG, RECORDING_SHEET_STATUS_CONFIG } from './constant';
import createRecordingSheetEDocParams from './serviceEdocParam';

interface Props {
	serviceStartDate?: Date;
	serviceEndDate?: Date;
	items?: RecordingSheetItem[];
	onClickRow?: (task: RecordingSheetItem) => void;
	checkIds: string[];
	handleToggleCheckAll: (e: ChangeEvent<HTMLInputElement>) => void;
	handleChangCheckBox: (e: ChangeEvent<HTMLInputElement>) => void;
	initCheckIds: () => void;
	onRefresh: () => void;
}

export const RecordingSheetPaperType: { [key: string]: string } = {
	'CMN006.10': 'CMN119.0054',
	'CMN006.20': 'CMN119.0053',
	'CMN006.30': 'CMN119.0052',
};

function RecordingSheetTable({
	items = [],
	serviceStartDate,
	serviceEndDate,
	checkIds,
	onClickRow,
	handleToggleCheckAll,
	handleChangCheckBox,
	initCheckIds,
	onRefresh,
}: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const { mutateAsync: getEFormFileName } = useEFormFileName();
	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: centerListData } = useCenterList();

	const myCenter = useMemo(
		() => (centerListData || []).find((item) => item.centerId === myAccountInfo?.centerId),
		[centerListData],
	);

	const renderCheckBox = useCallback(
		(id: string, item?: RecordingSheetItem) => (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					height: '3.3rem',
				}}>
				{item?.sendUseYn && (
					<CRCheckBox
						palette='gray'
						id={item?.id}
						checked={checkIds.includes(item?.id || '')}
						onChange={handleChangCheckBox}
					/>
				)}
			</div>
		),
		[checkIds],
	);

	const renderPhoneNumber = useCallback(
		(phoneNumber?: string) => <S.TextBox>{displayPhoneNumber(phoneNumber)}</S.TextBox>,
		[checkIds],
	);

	const renderRecordingSheetCount = useCallback(
		(recordingSheetCount?: number) => (
			<S.TextBox>{recordingSheetCount ? `${recordingSheetCount}개` : '-'}</S.TextBox>
		),
		[checkIds],
	);

	const renderSendStatus = useCallback(
		(value: string) => <CRStatus options={RECORDING_SHEET_STATUS_CONFIG}>{value}</CRStatus>,
		[],
	);

	const handleClickRecordingSheetDetail = (item?: RecordingSheetItem) => () => {
		showDialog(() => <RecordingSheetDetailDialog item={item} />);
	};

	const onSubmit = (data: ESignRequestForm, item: any) => {
		if (item && serviceStartDate && serviceEndDate) {
			hideDialog();
			showDialog(() => (
				<SendRecordingSheetDialog
					serviceStartDate={serviceStartDate}
					serviceEndDate={serviceEndDate}
					items={[item]}
					initCheckIds={initCheckIds}
					onRefresh={onRefresh}
					eSignWayData={data}
				/>
			));
		}
	};

	const handleClickSendRecordingSheet = (item?: RecordingSheetItem) => () => {
		if (item && serviceStartDate && serviceEndDate) {
			showDialog(() => <ESignWayDialog onSubmit={onSubmit} item={item} />);
		}
	};

	const handleClickPreviewRecordingSheet = (item?: RecordingSheetItem) => async () => {
		if (item && myCenter) {
			const crfeNmRes = await getEFormFileName({
				paperTypeCd: RecordingSheetPaperType[item.serviceTypeCd],
				targetDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
			});

			if (crfeNmRes?.data?.eformFileNm) {
				const eDocParamValue = createRecordingSheetEDocParams(
					item,
					myCenter,
					crfeNmRes.data.eformFileNm,
				);

				showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={eDocParamValue} />);
			}
		}
	};

	const renderOption = (value: { [key: string]: RecordingDetail[] }, item?: RecordingSheetItem) => (
		<S.ButtonContainer>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={handleClickRecordingSheetDetail(item)}>
				기록지 확인
			</CRButton.Default>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={handleClickPreviewRecordingSheet(item)}>
				미리보기
			</CRButton.Default>
			{item?.sendUseYn ? (
				<CRButton.Default
					size='xSmall'
					palette='gray'
					onClick={handleClickSendRecordingSheet(item)}>
					발송
				</CRButton.Default>
			) : (
				<RDTooltip
					content={
						<>
							발송 후 서명완료되지 않은
							<br />
							일자가 포함돼있습니다.
						</>
					}
					side='top'>
					<CRButton.Default size='xSmall' disabled palette='gray'>
						발송
					</CRButton.Default>
				</RDTooltip>
			)}
		</S.ButtonContainer>
	);

	return (
		<S.Container>
			<CRTable.Root
				style={{
					borderLeft: `0.1rem solid ${Colors.gray90}`,
					borderRight: `0.1rem solid ${Colors.gray90}`,
				}}>
				<CRTable.Head
					offset={1}
					onToggleCheck={handleToggleCheckAll}
					heads={RECORDING_SHEET_HEADER_CONFIG}
				/>
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.id}
							item={{
								...item,
								check: item.id,
							}}
							renderKeys={[
								'check',
								'recipientNm',
								'recipientMobilePhoneNo',
								'serviceTypeNm',
								'employeeNm',
								'employeeMobilePhoneNo',
								'employeeNm2',
								'employeeMobilePhoneNo2',
								'recordingCnt',
								'sendStatus',
								'managerNm',
								'recordingDetails',
							]}
							customRender={{
								check: renderCheckBox,
								recipientMobilePhoneNo: renderPhoneNumber,
								employeeMobilePhoneNo: renderPhoneNumber,
								employeeMobilePhoneNo2: renderPhoneNumber,
								recordingCnt: renderRecordingSheetCount,
								sendStatus: renderSendStatus,
								recordingDetails: renderOption,
							}}
							customStyle={{
								check: {
									display: 'flex',
									textAlign: 'center',
									justifyContent: 'center',
								},
							}}
							onClick={onClickRow}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default RecordingSheetTable;
