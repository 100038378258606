import React, { useCallback } from 'react';
import Assets from 'assets';

import CRTable from 'components/base/CRTable';
import CRButton from 'components/base/CRButton';
import { Recipient, RecipientOwnExpenseHistoryType } from 'types/view/recipient';
import CRStatus from 'components/base/CRStatus';
import CRDialog from 'components/base/CRDialog';
import { recipientHistoryOfOwnExpenseAdapter } from 'lib/adapter/recipient';
import {
	useDeleteRecipientHistoryOfOwnExpense,
	useRecipientHistoryOfOwnExpense,
} from 'lib/hook/react-query';

import useDialog from 'lib/hook/util/useDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import dayjs from 'dayjs';
import { Toast } from 'components/base/CRToast';
import * as S from './styles';
import {
	RECIPIENT_OWN_EXPENSE_HISTORY_STATUS_CONFIG,
	RECIPIENT_OWN_EXPENSE_HISTORY_TABLE_HEADER_CONFIG,
} from './constants';
import { endpoint } from '../../../../lib/service/Api/endpoint';
import RecipientUpdateHistoryOfOwnExpenseDialog from '../RecipientUpdateHistoryOfOwnExpenseDialog';
import DefaultDialog from '../DefaultDialog';

interface Props {
	currentRecipient?: Recipient;
	onClickClose?: () => void;
	onClickAdd?: () => void;
}

function RecipientOwnExpenseHistoryDialog({
	currentRecipient,
	onClickClose,
	onClickAdd,
}: Props): React.ReactElement {
	const dialog = useDialog();
	const { data: historyOfOwnExpense = [] } = useRecipientHistoryOfOwnExpense(
		{
			recipientId: currentRecipient?.recipientId,
		},
		recipientHistoryOfOwnExpenseAdapter,
	);

	const deleteRecipientHistoryOfOwnExpenseMutation = useDeleteRecipientHistoryOfOwnExpense(
		(client, res) => {
			if (!res?.recipientId) {
				Toast.success('본인부담율 이력이 삭제를 실패했습니다.');
				return;
			}
			Toast.success('본인부담율 이력이 삭제되었습니다.');
			client.invalidateQueries([
				endpoint.getRecipientHistoryOfOwnExpense.key,
				{ recipientId: currentRecipient?.recipientId },
			]);
			client.invalidateQueries([
				endpoint.getRecipientBase.key,
				{ recipientId: Number(currentRecipient?.recipientId) },
			]);
		},
	);

	const handleClickUpdateHistoryOfOwnExpense = (item: RecipientOwnExpenseHistoryType) => {
		dialog.showDialog(({ hideDialog }) => (
			<RecipientUpdateHistoryOfOwnExpenseDialog
				burdenRateHistItem={item}
				currentRecipient={currentRecipient}
				onClickClose={hideDialog}
			/>
		));
	};

	const handleClickDeleteHistoryOfOwnExpense = async (item: RecipientOwnExpenseHistoryType) => {
		if (deleteRecipientHistoryOfOwnExpenseMutation.isLoading) return;
		const targetDate = dayjs(item.date).format('YYYY년 MM월 DD일');
		dialog.showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='삭제'
				hideDialog={hideDialog}
				content={`${targetDate} 본임부담율 이력을 삭제합니다.`}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '삭제',
					successCallback: async () => {
						deleteRecipientHistoryOfOwnExpenseMutation.mutateAsync({
							recipientId: currentRecipient?.recipientId ?? 0,
						});
						dialog.hideDialog();
					},
				}}
			/>
		));
	};

	const Status = useCallback(
		(status: string) => (
			<CRStatus options={RECIPIENT_OWN_EXPENSE_HISTORY_STATUS_CONFIG}>{status}</CRStatus>
		),
		[],
	);

	const ButtonContainer = useCallback(
		(id: string, item?: RecipientOwnExpenseHistoryType) => {
			if (item?.status !== '현재') return <div />;

			return (
				<FlexContainer gap='0.4rem'>
					<CRButton.Default
						type='outlined'
						palette='gray'
						size='xSmall'
						onClick={() => handleClickUpdateHistoryOfOwnExpense(item)}>
						수정
					</CRButton.Default>
					{historyOfOwnExpense.length > 1 && (
						<CRButton.Default
							type='outlined'
							palette='primary'
							size='xSmall'
							onClick={() => handleClickDeleteHistoryOfOwnExpense(item)}>
							삭제
						</CRButton.Default>
					)}
				</FlexContainer>
			);
		},
		[historyOfOwnExpense.length, deleteRecipientHistoryOfOwnExpenseMutation],
	);

	const dateRenderer = (val: string) => dayjs(val).format('YYYY.MM.DD');

	return (
		<CRDialog
			type='L'
			title='본인부담율 이력'
			body={
				<S.Container>
					<CRTable.Root>
						<CRTable.Head
							heads={RECIPIENT_OWN_EXPENSE_HISTORY_TABLE_HEADER_CONFIG}
							disableBackBlind
						/>
						<CRTable.Body>
							{historyOfOwnExpense.map((item) => (
								<CRTable.Row
									key={item.id}
									item={item}
									customRender={{
										status: Status,
										id: ButtonContainer,
										date: dateRenderer,
										manager: (item) => item.name,
										center: (item) => item.name,
									}}
									renderKeys={[
										'status',
										'date',
										'ownExpenseRate',
										'note',
										'center',
										'manager',
										'id',
									]}
								/>
							))}
						</CRTable.Body>
					</CRTable.Root>
				</S.Container>
			}
			footer={
				<S.FooterRightSideContainer>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						type='outlined'
						palette='gray'
						onClick={onClickAdd}>
						본인부담율 이력 추가
					</CRButton.IconButton>
				</S.FooterRightSideContainer>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default RecipientOwnExpenseHistoryDialog;
