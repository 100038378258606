import {
	CenterDTO,
	ConfirmRecipientEdocAdmissionUseRequest,
	RecipientEdocAdmissionUseService,
} from 'types/api';
import { AdmissionUseRequestFormTypes } from 'types/view/eDoc';
import dayjs from 'dayjs';
import { Esign } from 'types/api/send';

export enum AdmissionUseCd {
	신규 = 'CMN157.10',
	갱신 = 'CMN157.20',
	변경 = 'CMN157.30',
	해지 = 'CMN157.99',
}

enum ServiceTypeCd {
	방문요양 = 'CMN006.10',
	방문목욕 = 'CMN006.20',
	방문간호 = 'CMN006.30',
}

export const eDocAdmissionUseConfirmRequestAdapter = (
	formData: AdmissionUseRequestFormTypes,
	esign: Esign,
) => {
	const admissionUseCdMap = Object.fromEntries(Object.entries(AdmissionUseCd));
	const serviceTypeCdCdMap = Object.fromEntries(Object.entries(ServiceTypeCd));
	const data = formData.admissionUseEdocData;
	if (!data?.edocNo) return undefined;

	let serviceTotalAmt = 0;

	const param: ConfirmRecipientEdocAdmissionUseRequest = {
		centerId: data.centerId,
		signatoryId: esign.signatoryId,
		signDivCd: '',
		edocNo: data?.edocNo,
		sendUuid: esign.sendUuid,
		birthDt: '',
		confirmYn: true,
		recipientEdocAdmissionUseRequest: {
			paperTypeCd: data.paperTypeCd,
			admissionUseDivCd: admissionUseCdMap[formData.type.value],
			requestDt: formData?.requestDate ? dayjs(formData.requestDate).format('YYYY-MM-DD') : '',
			requestManagerBirthDt: formData.birthDt || '',
			recipientAddr: formData.recipientAddr || '',
			recipientRsdnNo: formData.recipientRsdnNo || '',
			longTermNo: formData.longTermNo || '',
			longTermGradeCd: formData.longTermGradeCd?.value || '',
			admissionUseGubun1: data.admissionUseGubun1,
			admissionUseGubun2: data.admissionUseGubun2,
			useStartDt: dayjs(formData.startEndDate.start).format('YYYY-MM-DD'),
			useEndDt: formData.startEndDate.end
				? dayjs(formData.startEndDate.end).format('YYYY-MM-DD')
				: dayjs().format('YYYY-MM-DD'),
			useServices: formData.service.map((item, index) => {
				const serviceAmtOfMonth =
					item.serviceFee && item.serviceNumberOfMonth
						? Number(item.serviceFee) * Number(item.serviceNumberOfMonth)
						: 0;
				serviceTotalAmt += serviceAmtOfMonth;

				const serviceItem: RecipientEdocAdmissionUseService = {
					serviceTypeCd: serviceTypeCdCdMap[item.serviceType?.value] || '',
					serviceDesc: item.serviceDesc || '',
					serviceFeeAmt: Number(item.serviceFee),
					serviceCntOfMonth: Number(item.serviceNumberOfMonth),
					serviceAmtOfMonth,
					serviceCenterMark: data.centerMark,
					serviceCenterNm: data.centerNm,
				};

				if (formData?.service?.length && index === formData.service.length - 1) {
					serviceItem.serviceTotalAmt = serviceTotalAmt;
				}
				return serviceItem;
			}),
			useWelfare: data.useWelfare,
		},
	};
	return param;
};
