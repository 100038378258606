import React, {
	useRef,
	useState,
	useEffect,
	useCallback,
	useLayoutEffect,
	CSSProperties,
} from 'react';
import { useApi } from 'lib/provider/service/Api';
import { EDoc } from 'types/view/eDoc';
import CRSpinner from 'components/base/CRSpinner';

import CRIcon from 'components/base/CRIcon';
import Assets from 'assets';
import { CRText } from 'components/base/CRText';
import CRButton from 'components/base/CRButton';
import FontFaceObserver from 'fontfaceobserver';
import useDialog from 'lib/hook/util/useDialog';
import * as S from '../../styles';

declare global {
	interface Window {
		createImportJSPEForm: (url: string, eformKey: string, target: HTMLElement | null) => EDoc;
		createReport: (url: string, reportKey: string, target: HTMLElement | null) => EDoc;
	}
}

interface IProps {
	showMenu?: boolean;
	viewerType?: 'eform' | 'report';
	viewerMode?: 'fullPage';
	showCustomHeader?: boolean;
	dataType?: 'json' | 'db';
	crfeName: string;
	style?: CSSProperties;
	params?: any;
	showImgDownloader?: boolean;
	eDocTitle?: string;
}

function ClipViewer({
	showMenu,
	viewerType = 'report',
	dataType = 'json',
	crfeName,
	viewerMode,
	style,
	params = {},
	showCustomHeader,
	showImgDownloader = true,
	eDocTitle = '',
}: IProps): React.ReactElement {
	const isEDoc = window.location.pathname?.includes('/edoc');
	const { hideDialog } = useDialog();
	const api = useApi();
	const containerRef = useRef<HTMLDivElement>(null);
	const eFormTarget = useRef<HTMLDivElement>(null);
	const eForm = useRef<EDoc>();
	const [containerWidth, setContainerWidth] = useState(0);
	const [scriptLoadedObj, setScriptLoadedObj] = useState({
		scriptJquery: false,
		clipReportJS: false,
		userConfigJs: false,
	});

	const isAllScriptLoaded = Object.values(scriptLoadedObj).every((val) => val === true);

	const loadDocument = useCallback(async () => {
		if (!crfeName) return;
		const data = {
			crfeNm: `${crfeName}`,
			params,
		};
		const path = `${viewerType}/${dataType}/key`;
		const eFormParam = await api.getEDoc({
			path,
			param: data,
		});

		eForm.current =
			viewerType === 'eform'
				? window.createImportJSPEForm(
						`${process.env.REACT_APP_EFORM_URL}/clip`,
						eFormParam,
						eFormTarget.current,
				  )
				: window?.createReport(
						`${process.env.REACT_APP_EFORM_URL}/report_server`,
						eFormParam,
						eFormTarget.current,
				  );

		eForm.current.setStyle('close_button', 'display:none;');
		if (viewerType === 'eform') {
			eForm.current.setStyle('save_button', 'right:10px;');
			eForm.current.setStyle('print_button', 'display:none;');
			eForm.current.setStyle('info_button', 'display:none;');

			eForm.current?.setNecessaryEnabled(true);
			eForm.current?.setEndSaveButtonEvent(() => {
				alert('반영되었습니다.');
			});
		} else {
			// eForm.current.setStyle('save_button', 'display:none;');
			// eForm.current.setStyle('print_button', 'display:none;');
			eForm.current.setStyle('info_button', 'display:none;');
			eForm.current?.setStartPrintButtonEvent(() => {
				eForm.current?.callHTML5Print();
				return false;
			});
		}

		if (viewerMode === 'fullPage') {
			eForm.current?.setVisibleMenu(false);
			eForm.current?.setDefaultRatio('PageWidth');
			eForm.current?.setScrollView(true);
		}

		if (showMenu) {
			eForm.current?.setVisibleMenu(true);
		}

		eForm.current?.view();
	}, [crfeName, params]);

	const customDownloadPDF = async (fileName: string) => {
		if (!eForm?.current?.callSaveFileDownLoad) return;
		eForm.current.callSaveFileDownLoad(fileName, 10, 1);
	};

	useLayoutEffect(() => {
		const scriptJquery = document.createElement('script');
		const clipReportJS = document.createElement('script');
		const userConfigJs = document.createElement('script');

		const clipReportCss = document.createElement('link');
		const userConfigCss = document.createElement('link');

		clipReportCss.rel = 'stylesheet';
		userConfigCss.rel = 'stylesheet';

		clipReportCss.type = 'text/css';
		userConfigCss.type = 'text/css';

		scriptJquery.src = `${process.env.PUBLIC_URL}/eform/js/jquery-1.11.1.js`;
		scriptJquery.type = 'text/javascript';
		clipReportJS.type = 'text/javascript';
		userConfigJs.type = 'text/javascript';

		if (viewerType === 'report') {
			clipReportJS.src = `${process.env.PUBLIC_URL}/eform/js/clipreport5.js`;
			userConfigJs.src = `${process.env.PUBLIC_URL}/eform/js/UserConfig5.js`;
			clipReportCss.href = `${process.env.PUBLIC_URL}/eform/css/clipreport5.css`;
			userConfigCss.href = `${process.env.PUBLIC_URL}/eform/css/UserConfig5.css`;
		} else {
			clipReportJS.src = `${process.env.PUBLIC_URL}/eform/js/clipreport.js?ver=1.0`;
			userConfigJs.src = `${process.env.PUBLIC_URL}/eform/js/UserConfig.js`;
			clipReportCss.href = `${process.env.PUBLIC_URL}/eform/css/clipreport.css`;
			userConfigCss.href = `${process.env.PUBLIC_URL}/eform/css/UserConfig.css`;

			const eFormCss = document.createElement('link');
			eFormCss.rel = 'stylesheet';
			eFormCss.type = 'text/css';
			eFormCss.href = `${process.env.PUBLIC_URL}/eform/css/eform.css`;
			document.head.appendChild(eFormCss);
		}

		scriptJquery.onload = () => {
			setScriptLoadedObj((prev) => ({ ...prev, scriptJquery: true }));
		};
		clipReportJS.onload = () => {
			setScriptLoadedObj((prev) => ({ ...prev, clipReportJS: true }));
		};
		userConfigJs.onload = () => {
			setScriptLoadedObj((prev) => ({ ...prev, userConfigJs: true }));
		};

		// const fontFile = new FontFace(
		// 	'독립서체 한용운 GS',
		// 	'url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/HAN-YONG-UN.woff")format("woff")',
		// );
		// document.fonts.add(fontFile);
		//
		// fontFile
		// 	.load()
		// 	.finally(() => setScriptLoadedObj((prev) => ({ ...prev, fontHanYoungUn: true })));

		document.head.appendChild(clipReportCss);
		document.head.appendChild(userConfigCss);
		document.body.appendChild(scriptJquery);
		document.body.appendChild(clipReportJS);
		document.body.appendChild(userConfigJs);

		// eslint-disable-next-line
		return () => {
			scriptJquery.remove();
			clipReportJS.remove();
			userConfigJs.remove();
			// clipReportCss.remove();
			// userConfigCss.remove();
		};
	}, [dataType, viewerType]);

	useLayoutEffect(() => {
		api.initEDocClient();

		// eslint-disable-next-line
		return () => {
			api.removeEDocClient();
		};
	}, [api]);

	useEffect(() => {
		if (isAllScriptLoaded) {
			new FontFaceObserver('독립서체 한용운 GS').load().then(() => {
				loadDocument();
			});

			if (containerRef.current?.clientWidth) {
				setContainerWidth(containerRef.current.clientWidth);
			}
		}
	}, [crfeName, params, isAllScriptLoaded]);

	const fileName = eDocTitle || '케어링-전자문서';

	return (
		<>
			{showCustomHeader && (
				<>
					<S.EDocViewerHeader>
						<CRText typography='bodyB' text={fileName} />
						{isEDoc && (
							<CRButton.Default
								palette='gray'
								type='outlined'
								style={{ padding: '0 1rem' }}
								onClick={() => customDownloadPDF(fileName)}>
								<CRIcon src={Assets.icon.fileDownload} />
								<CRText typography='label' text='다운로드' margin='0 0 0 0.8rem' />
							</CRButton.Default>
						)}
					</S.EDocViewerHeader>
					<S.EmptyBox />
				</>
			)}
			<S.CurrentViewerContainer ref={containerRef}>
				<S.ViewerContainer
					$showImgDownload={showImgDownloader}
					ref={eFormTarget}
					style={{
						...style,
						...(showCustomHeader
							? {
									position: 'fixed',
									top: '5.6rem',
									height: isEDoc ? window.innerHeight : `${window.innerHeight - 128}px`,
							  }
							: {}),
						width: containerWidth,
					}}
				/>
				{!isAllScriptLoaded && <CRSpinner />}
				{showImgDownloader && (
					<S.DownloadButtonContainer>
						<CRButton.Default type='text' palette='gray' onClick={hideDialog}>
							닫기
						</CRButton.Default>
						<CRButton.Default onClick={() => customDownloadPDF(fileName)}>
							이미지 저장
						</CRButton.Default>
					</S.DownloadButtonContainer>
				)}
			</S.CurrentViewerContainer>
			{/* </FlexContainer> */}
		</>
	);
}

export default React.memo(ClipViewer);
