import { styled } from 'styled-components';

export const Container = styled.div`
	position: relative;
	overflow: auto;
`;

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 2.4rem 2.4rem 1.6rem 2.4rem;
	box-sizing: border-box;
`;

export const BodyContainer = styled.div`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};
	flex: 1;
	display: flex;
	flex-direction: column;
	background: ${(props) => props.theme.colors.gray99};
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	overflow: overlay;
`;

export const FooterContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	box-sizing: border-box;
	gap: 0.8rem;
`;

export const FooterRightSideContainer = styled.div`
	flex: 1;
`;

export const HeaderTitle = styled.h3`
	flex: 1;
`;

export const CloseIcon = styled.img`
	cursor: pointer;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const OptionContainer = styled.div`
	display: flex;
	align-items: center;
	min-height: 3.3rem;
	gap: 0.4rem;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const TextBox = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
