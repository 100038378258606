import React from 'react';
import { v4 } from 'uuid';
import * as S from './styles';

interface Props {
	items: string[];
}

export function CRTextBox({ items }: Props) {
	return (
		<S.Container>
			{items.map((item) => (
				<S.Item key={v4()}>{item}</S.Item>
			))}
		</S.Container>
	);
}
