import styled from 'styled-components';

export const Container = styled.div`
	overflow: auto;
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	width: 100%;
	min-height: 3.3rem;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;
