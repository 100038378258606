import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';

import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CRText } from 'components/base/CRText';
import { ClientDivCdObj } from 'components/domain/helper/ConsultingForm';
import * as S from './styles';

interface Props {
	disabled: boolean;
}

function WorkExecuteLogGuardianConsultForm({ disabled }: Props): React.ReactElement {
	const { form, advisorOptions } = useWorkExecuteInspectionLog();
	const advisor = useMemo(
		() =>
			advisorOptions?.map((item) => {
				const info = `${item?.mainGuardianYn ? '주보호자' : ClientDivCdObj[item.clientDivCd]}`;
				const detail =
					item.clientDivCd === 'CMN110.20'
						? `${item.clientBirth ? ` ・ ${item.clientBirth}` : ''}`
						: `${item.telNum ? ` ・ ${item.telNum}` : ''}`;

				return {
					label: (
						<CRText
							typography='body'
							color='gray10'
							text={
								<CRText
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: '0.6rem',
									}}
									color={disabled ? 'gray60' : 'gray10'}
									typography='body'
									text={
										<>
											{item.clientNm}
											<CRText text={info + detail} typography='label' color='gray60' />
										</>
									}
								/>
							}
						/>
					),
					value: item.clientId,
					data: item,
				};
			}) || [],
		[advisorOptions],
	);

	return (
		<S.Container>
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInputLabel label='상담자' isRequired type='left'>
						<CRCheckBoxGroup
							disabled
							direction='col'
							onChange={onChange}
							appearanceType='button'
							checkType='single'
							type='radio'
							gap={0.8}
							value={value}
							options={advisor}
						/>
					</CRInputLabel>
				)}
				name='client'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value, ref, onBlur } }) => (
					<CRInputLabel label='상담 내용' type='left' isRequired>
						<CRInput.TextArea
							disabled={disabled}
							maxLength={2000}
							fixedHeight
							ref={ref}
							height='21rem'
							placeholder='상담 내용 입력'
							onChange={onChange}
							onBlur={onBlur}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='consultDesc'
				control={form?.control}
			/>
		</S.Container>
	);
}

export default React.memo(WorkExecuteLogGuardianConsultForm);
