import React from 'react';
import { Controller } from 'react-hook-form';

import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';

import * as S from './styles';

interface Props {
	disabled: boolean;
}

function EDocWorkExecuteLogCheckForm({ disabled }: Props): React.ReactElement {
	const { form, commonCodes } = useWorkExecuteInspectionLog();

	const { serviceTypeCd } = form.watch();

	const isVisibleEmployee2 = serviceTypeCd
		? serviceTypeCd?.[0]?.value === ServiceTypeCd.방문목욕
		: false;

	return (
		<S.Container>
			<Controller
				render={({ field: { onChange, value, ref, onBlur } }) => (
					<CRInputLabel label='확인 내용' type='top'>
						<CRInput.TextArea
							disabled={disabled}
							fixedHeight
							ref={ref}
							height='14rem'
							maxLength={2000}
							placeholder='확인 내용 입력'
							onChange={onChange}
							onBlur={onBlur}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='salaryrecogActpgOfferConfirmDesc'
				control={form?.control}
			/>
			<Controller
				render={({ field: { onChange, value, ref, onBlur } }) => (
					<CRInputLabel label='조치 사항' type='top'>
						<CRInput.TextArea
							disabled={disabled}
							fixedHeight
							ref={ref}
							height='14rem'
							maxLength={2000}
							placeholder='조치사항 입력'
							onChange={onChange}
							onBlur={onBlur}
							value={value}
						/>
					</CRInputLabel>
				)}
				name='salaryrecogActpgOfferActionDesc'
				control={form?.control}
			/>
			<CRInputLabel type='top' label='급여제공자' isRequired showOverflow>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInput.SearchSelector
							disabled={disabled}
							currentValue={value}
							items={commonCodes?.employeesOptions}
							onChange={onChange}
							placeholder='급여제공자1 선택'
						/>
					)}
					name='employee1'
					control={form?.control}
				/>
				{isVisibleEmployee2 && (
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInput.SearchSelector
								disabled={disabled}
								currentValue={value}
								items={commonCodes?.employeesOptions}
								onChange={onChange}
								placeholder='급여제공자2 선택'
							/>
						)}
						name='employee2'
						control={form?.control}
					/>
				)}
			</CRInputLabel>
		</S.Container>
	);
}

export default React.memo(EDocWorkExecuteLogCheckForm);
