import { styled } from 'styled-components';

export const Container = styled.ul`
	padding: 1.6rem;
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray70};
	background: #f9f9f9;

	border-radius: ${(props) => props.theme.radius.radius01};
`;

export const Item = styled.li``;
