import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 2.4rem;
	flex: 1;
	padding: 1.6rem 2.4rem 2.4rem 2.4rem;
	overflow: auto;
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	box-sizing: border-box;
`;

export const SectionTitle = styled.div`
	${(props) => props.theme.typography.label};
	display: flex;
	justify-content: space-between;
	color: ${(props) => props.theme.colors.gray10};
`;

export const LeftSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	flex: 1;
`;

export const RightSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 60rem;
	min-width: 60rem;
	height: 100%;
`;

export const TableValue = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray10};
`;

export const TextBox = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 3.3rem;
`;

export const TableContainer = styled.div`
	height: 42rem;
	overflow: auto;
	margin-top: 0.6rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	width: 100%;
	height: 100%;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;

export const SendStatusContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
`;
