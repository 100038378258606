import React, { useMemo } from 'react';

import InformationTable from 'components/ui/InformationTable';
import CRRequiredMark from 'components/base/CRRequiredMark';
import CRButton from 'components/base/CRButton';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import CRStatus from 'components/base/CRStatus';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import {
	useContractDocument,
	useContractSendStatus,
	useCreateRecipientRenewalDocument,
	useGetRecipientRenewalDocument,
	useSendRecipientRenewalContract,
} from 'lib/hook/react-query';

import { ESignWayCode, ESignWayDialog } from 'components/domain/dialog/ESignDialog';
import { ESignRequestForm, LastESignStateCd } from 'types/view/eDoc';
import { SendRecipientContractRenewalRequest } from 'types/api/contract';
import { CONTRACT_LIST_TABLE_HEADER } from './constant';
import * as S from './styles';
import EDocNoDialog from '../../dialog/EdocNoDialog';
import { Toast } from '../../../base/CRToast';
import DefaultDialog from '../../dialog/DefaultDialog';
import UploadContractDocument from '../../dialog/UploadContractDocument';

function RenewalContractDocumentForm(): React.ReactElement {
	const { form, disabled, validateCreateDocument } = useContractRenewalTask();
	const { showDialog, hideDialog } = useGlobalLayout();
	const { data: contractSendStatus, refetch: refetchContractSendStatus } = useContractSendStatus({
		serviceContractId: form.getValues('services')?.[0].serviceContractId,
	});
	const { data: contractDocument, refetch: refetchContractDocument } = useContractDocument({
		serviceContractIds: (form.getValues('services') ?? []).map((item) => item.serviceContractId),
	});
	const getRecipientRenewalDocument = useGetRecipientRenewalDocument();
	const createRecipientRenewalDocument = useCreateRecipientRenewalDocument();
	const sendRecipientRenewalContract = useSendRecipientRenewalContract();

	const sendList = useMemo(() => {
		const contractEdocSendResponse = (contractSendStatus ?? []).find(
			(item) => item.paperTypeCd === 'CMN119.0058',
		)?.contractEdocSendResponse;
		const recipientSend = {
			title: `수급자 ${form.getValues('recipient.base.recipientNm')}(${form.getValues(
				'recipient.base.recipientId',
			)})`,
			items: [
				{
					type: 'recipient',
					title: '수급자 계약서',
					isRequired: true,
					fileName: '수급자_계약서.eform',
					status: contractEdocSendResponse?.lastEsignStateCd ?? 'CMN031.10',
					data: {
						serviceContractIds: (form.getValues('services') ?? []).map(
							(item) => item.serviceContractId,
						),
						receiverName: form.getValues('recipient.base.recipientNm'),
					},
					document: contractDocument?.filter((item) => item.paperTypeCd === 'CMN119.0058'),
				},
			] as {
				type: 'employee' | 'recipient';
				title: string;
				isRequired: boolean;
				fileName: string;
				status: string;
				data: any;
				document: any[];
			}[],
		};

		return [recipientSend];
	}, [
		form.getValues('recipient'),
		form.getValues('services'),
		contractSendStatus,
		contractDocument,
	]);

	const handleClickCreateDocument = async (item: any) => {
		if (!validateCreateDocument?.()) return;
		if (item.type === 'recipient') {
			if (createRecipientRenewalDocument.isLoading) return;
			await createRecipientRenewalDocument.mutateAsync({
				serviceContractIds: (form.getValues('services') ?? []).map(
					(item) => item.serviceContractId,
				),
			});
			Toast.success('계약서를 성공적으로 발급했습니다.');
			refetchContractSendStatus();
			refetchContractDocument();
			return;
		}
	};

	const handleClickReCreateDocument = async (item: any) => {
		if (!validateCreateDocument?.()) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				hideDialog={hideDialog}
				title='계약서 재발급'
				content='기존 문서를 취소하고 재발급 받겠습니까?'
				successOption={{
					text: '재발급',
					successCallback: async () => {
						try {
							if (item.type === 'recipient') {
								if (createRecipientRenewalDocument.isLoading) return;
								await createRecipientRenewalDocument.mutateAsync({
									serviceContractIds: (form.getValues('services') ?? []).map(
										(item) => item.serviceContractId,
									),
								});
								refetchContractSendStatus();
								refetchContractDocument();
								hideDialog();
								Toast.success('계약서를 성공적으로 재발급했습니다.');
								return;
							}
						} catch {
							Toast.error('계약서 재발급에 실패하였습니다. 잠시 후 다시 시도해주세요.');
						}
					},
				}}
			/>
		));
	};

	const handleClickRecipientSend = async (data: any) => {
		const onSubmitRecipient = async (eSignWayData: ESignRequestForm, item: any) => {
			try {
				if (sendRecipientRenewalContract.isLoading) return false;
				const param: SendRecipientContractRenewalRequest =
					eSignWayData.eSignWayCd?.[0]?.value === ESignWayCode.대면서명
						? {
								recipientContractIds: item.document.map((target: any) => target.contractDocumentId),
								esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
								facingManagerId: eSignWayData.receiver?.data?.memberAccountId,
								facingManagerPhoneUsageDivCd:
									eSignWayData?.facingManagerPhoneUsageDivCd?.[0]?.value,
						  }
						: {
								recipientContractIds: item.document.map((target: any) => target.contractDocumentId),
								esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
						  };
				await sendRecipientRenewalContract.mutateAsync(param);
				refetchContractSendStatus();
				hideDialog();
				Toast.success('계약서를 성공적으로 발송했습니다.');
			} catch {
				Toast.error('계약서 발송에 실패하였습니다. 잠시 후 다시 시도해주세요.');
			}
			return undefined;
		};

		showDialog(() => <ESignWayDialog onSubmit={onSubmitRecipient} item={data} />);
	};

	const handleClickPreview = async (item: any) => {
		if (item.type === 'recipient') {
			const data = await getRecipientRenewalDocument.mutateAsync({
				recipientContractIds: item.document?.map((target: any) => target.contractDocumentId),
			});

			if (!data) return;
			showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={JSON.parse(data)} />);
			return;
		}
	};

	const handleClickSend = (item: any) => {
		if (item.type === 'recipient') {
			handleClickRecipientSend(item);
			return;
		}
	};

	const handleClickUpload = (item: any) => {
		if (item.type === 'recipient') {
			showDialog(() => (
				<UploadContractDocument
					contractDocumentId={item.document.map((target: any) => target.contractDocumentId)}
					type='recipientRenewal'
				/>
			));
			return;
		}
		if (item.type === 'employee') {
			showDialog(() => (
				<UploadContractDocument
					contractDocumentId={item.document.contractDocumentId}
					type='employee'
				/>
			));
			return;
		}
	};

	console.log('sendList', sendList);
	return (
		<S.Container>
			{sendList.map((item) => (
				<S.SectionContainer>
					<S.SectionTitle>{item.title}</S.SectionTitle>
					<InformationTable
						items={[
							CONTRACT_LIST_TABLE_HEADER,
							...item.items.map(
								(send) =>
									[
										{
											type: 'value',
											value: (
												<div>
													{send.title} {send.isRequired && <CRRequiredMark />}
												</div>
											),
										},
										{
											type: 'value',
											value: send.fileName,
										},
										{
											type: 'value',
											value: (
												<CRStatus
													options={[
														{
															key: 'CMN031.10',
															label: '발송전',
															color: 'gray',
														},
														{
															key: LastESignStateCd.서명대기,
															label: '서명 대기',
															color: 'yellow',
														},
														{
															key: LastESignStateCd.검수대기,
															label: '검수 대기',
															color: 'blue',
														},
														{
															key: LastESignStateCd.완료,
															label: '완료',
															color: 'green',
														},
														{
															key: LastESignStateCd.서명취소,
															label: '서명 취소',
															color: 'red',
														},
														{
															key: LastESignStateCd.기간만료,
															label: '기간 만료',
															color: 'red',
														},
													]}>
													{send.status}
												</CRStatus>
											),
										},
										{
											type: 'value',
											value: (
												<S.ButtonContainer>
													{!send.document?.length ? (
														<CRButton.Default
															size='xSmall'
															type='outlined'
															palette='gray'
															onClick={() => handleClickCreateDocument(send)}
															disabled={disabled}>
															발급
														</CRButton.Default>
													) : (
														<>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickReCreateDocument(send)}
																disabled={disabled}>
																재발급
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickPreview(send)}>
																미리보기
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickSend(send)}>
																{send.status === 'CMN031.10' ? '발송' : '재발송'}
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickUpload(send)}>
																업로드
															</CRButton.Default>
														</>
													)}
												</S.ButtonContainer>
											),
										},
									] as InformationTableItemType[],
							),
						]}
					/>
				</S.SectionContainer>
			))}
		</S.Container>
	);
}

export default RenewalContractDocumentForm;
