import React, { useEffect, useMemo } from 'react';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInput from 'components/base/CRInput';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
	AdmissionUseRequestFormTypes,
	AdmissionUseService,
	EDocIssueFormTypes,
} from 'types/view/eDoc';
import {
	useEDocIssueInfoBefore,
	useRecipientAdmissionUseEDoc,
} from 'lib/hook/react-query/query/edoc';
import { EDocAdmissionUseRequestDTO, EDocAdmissionUseRequestRecipient } from 'types/api/eDoc';
import CRButton from 'components/base/CRButton';
import { v4 } from 'uuid';
import Assets from 'assets';
import { useCenterManagers, useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { displayBirthDay } from 'lib';
import { CheckOption } from 'components/base/Selections/type';
import dayjs from 'dayjs';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import { DaumPostcodeData } from 'lib/hook/util/useDaumPostCode';
import { commonCodeAdapter } from 'lib/adapter/common';
import * as S from './styles';
import { AdmissionUseCd } from '../EDocFormConfirmDialog/eDocConfirmAdapter';

type ServiceWithId = AdmissionUseService & { id?: string };
type AdmissionUseRequestFormWithIdTypes = Omit<AdmissionUseRequestFormTypes, 'service'> & {
	service: ServiceWithId[];
};

export interface AdmissionUseRecipient {
	recipientId?: number;
	centerId?: number;
}

interface Props {
	templateCode: string;
	edocNo?: string;
	formContext: UseFormReturn<AdmissionUseRequestFormWithIdTypes, any, undefined>;
	confirmMode?: boolean;
	defaultContext?: UseFormReturn<EDocIssueFormTypes, any, undefined>;
	currentRecipientId?: number;
}

export default function EDocAdmissionUseRequestForm({
	templateCode,
	edocNo,
	formContext,
	confirmMode = false,
	defaultContext,
	currentRecipientId,
}: Props) {
	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: admissionUseEdocData } = useRecipientAdmissionUseEDoc({
		centerId: myAccountInfo?.centerId,
		edocNo,
	});

	// 서류탭에서 발급시 recipientId가 존재하여 그 아이디로 데이터 조회, 검수시에는 admissionUseEdocData에 포함된 데이터로 조회
	const { data: employmentCertificationIssueInfo } = useEDocIssueInfoBefore({
		recipientId: currentRecipientId || admissionUseEdocData?.recipientId,
		centerId: Number(myAccountInfo?.centerId),
		templateCode,
	});

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN042'],
		},
		commonCodeAdapter,
	);

	const gradeOptions = commonCodes?.CMN042 || [];

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const service = formContext.watch('service');
	const baseAddr = formContext.watch('recipientBaseAddr');

	const managerOptions = useMemo(
		() =>
			(centerManagers ?? []).map(
				(item) =>
					({
						label: item.userNm,
						value: {
							id: item.memberAccountId,
							name: item.userNm,
							duty: item.positionNm,
							birth: displayBirthDay(item.birthDt),
							workMobilePhoneNo: item.workMobilePhoneNo,
							personalMobilePhoneNo: item.personalMobilePhoneNo,
						},
						data: item,
					} as CheckOption),
			),
		[centerManagers],
	);

	const typesCodes = useMemo(
		() =>
			(
				(employmentCertificationIssueInfo as unknown as EDocAdmissionUseRequestDTO)?.data?.types ||
				[]
			)?.map((item) => ({
				label: item,
				value: item,
			})),
		[employmentCertificationIssueInfo],
	);

	const serviceTypesCodes = useMemo(
		() =>
			(
				(employmentCertificationIssueInfo as unknown as EDocAdmissionUseRequestDTO)?.data
					?.serviceTypes || []
			)?.map((item) => ({
				label: item,
				value: item,
			})),
		[employmentCertificationIssueInfo],
	);

	const handleClickAddService = () => {
		formContext.setValue('service', [
			...service,
			{
				id: v4(),
				serviceType: undefined,
				serviceDesc: undefined,
				serviceFee: undefined,
				serviceNumberOfMonth: undefined,
			},
		]);
	};

	const handleClickDeleteService = (id?: string) => {
		if (!id) return;
		formContext.setValue(
			'service',
			service.filter((item) => item.id !== id),
		);
	};

	const handleChangeBaseAddress = (postcodeData: DaumPostcodeData) => {
		formContext?.setValue('recipientBaseAddr', postcodeData.address);
	};

	useEffect(() => {
		if (employmentCertificationIssueInfo) {
			formContext.setValue('data', employmentCertificationIssueInfo);
		}
	}, [employmentCertificationIssueInfo]);

	useEffect(() => {
		if (!myAccountInfo || !managerOptions?.length || confirmMode) return;
		const consenter = managerOptions.find(
			(item) => item?.data?.memberAccountId === myAccountInfo?.memberAccountId,
		);
		if (consenter) {
			formContext.setValue('consenter', consenter);
		}
	}, [confirmMode, myAccountInfo, managerOptions]);

	useEffect(() => {
		if (admissionUseEdocData && managerOptions.length) {
			const getAdmissionDivCdNm = (admissionUseDivCd?: string) => {
				switch (admissionUseDivCd) {
					case AdmissionUseCd.신규: {
						return '신규';
					}
					case AdmissionUseCd.갱신: {
						return '갱신';
					}
					case AdmissionUseCd.변경: {
						return '변경';
					}
					case AdmissionUseCd.해지: {
						return '해지';
					}
					default: {
						return '';
					}
				}
			};
			const getServiceTypeNm = (serviceTypeCd?: string) => {
				switch (serviceTypeCd) {
					case ServiceTypeCd.방문요양: {
						return '방문요양';
					}
					case ServiceTypeCd.방문목욕: {
						return '방문목욕';
					}
					case ServiceTypeCd.방문간호: {
						return '방문간호';
					}
					default: {
						return '';
					}
				}
			};

			// const sss = console.log('sss', sss);/

			// 상위 폼의 발송정보 설정
			if (defaultContext) {
				defaultContext.setValue('recipientNm', admissionUseEdocData?.recipientNm);
				defaultContext.setValue('recipientPhoneNo', admissionUseEdocData?.recipientPhoneNo);
			}

			formContext.reset({
				type: {
					label: getAdmissionDivCdNm(admissionUseEdocData?.admissionUseDivCd),
					value: getAdmissionDivCdNm(admissionUseEdocData?.admissionUseDivCd),
				},
				requestDate: dayjs(admissionUseEdocData.requestDt).toDate(),
				consenter: managerOptions.find(
					(item) => item.value.id === admissionUseEdocData.edocSenderIds?.[0],
				),
				birthDt: admissionUseEdocData.requestManagerBirthDt,
				startEndDate: {
					start: dayjs(admissionUseEdocData.useStartDt).toDate(),
					end: dayjs(admissionUseEdocData.useEndDt).toDate(),
				},
				service: admissionUseEdocData.useServices.map((item) => ({
					id: v4(),
					serviceType: {
						label: getServiceTypeNm(item.serviceTypeCd),
						value: getServiceTypeNm(item.serviceTypeCd),
					},
					serviceDesc: item.serviceDesc,
					serviceFee: String(item.serviceFeeAmt),
					serviceNumberOfMonth: String(item.serviceCntOfMonth),
				})),
				recipientNm: admissionUseEdocData.recipientNm || '',
				recipientRsdnNo: admissionUseEdocData?.recipientRsdnNo
					? admissionUseEdocData?.recipientRsdnNo?.replace('-', '')
					: '',
				longTermGradeCd: {
					label: admissionUseEdocData.longTermGradeNm,
					value: admissionUseEdocData.longTermGradeCd,
				},
				longTermNo: admissionUseEdocData.longTermNo,
				recipientAddr: admissionUseEdocData.recipientAddr || '',
				admissionUseEdocData,
				confirmMode,
			});
		} else {
			formContext.setValue('service', [
				{
					id: v4(),
					serviceType: undefined,
					serviceDesc: undefined,
					serviceFee: undefined,
					serviceNumberOfMonth: undefined,
				},
			]);
		}
	}, [admissionUseEdocData, managerOptions, defaultContext, confirmMode]);

	useEffect(() => {
		const recipient = employmentCertificationIssueInfo?.data
			?.recipient as unknown as EDocAdmissionUseRequestRecipient;
		if (!confirmMode && recipient) {
			formContext.setValue('recipientNm', recipient?.recipient?.recipientNm);
			formContext.setValue('recipientRsdnNo', recipient?.recipient?.rsdnNo);
			formContext.setValue('longTermGradeCd', {
				label: recipient?.recipient?.longTermGradeNm,
				value: recipient?.recipient?.longTermGradeCd,
			});
			formContext.setValue('longTermNo', recipient?.recipient?.longTermNo);
			formContext.setValue('recipientBaseAddr', recipient?.recipient?.baseAddr);
			formContext.setValue('recipientDetailAddr', recipient?.recipient?.detailAddr);
		}
	}, [confirmMode, employmentCertificationIssueInfo]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='유형' isRequired>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.Selector
								currentValue={value}
								onChangeValue={onChange}
								items={typesCodes}
								placeholder='유형 선택'
							/>
						)}
						name='type'
						control={formContext.control}
					/>
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, value } }) => (
						<CRInputLabel label='신청일자' isRequired>
							<S.DateContainer>
								<CRInput.DatePicker
									value={value}
									onChangeValue={onChange}
									placeholder='신청일자 선택'
								/>
							</S.DateContainer>
						</CRInputLabel>
					)}
					name='requestDate'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, value } }) => (
						<CRInputLabel label='이용기간' isRequired>
							<CRInput.DateRangePicker
								placeholder='00.00.00 ~ 00.00.00'
								onChangeValue={onChange}
								value={value}
							/>
						</CRInputLabel>
					)}
					name='startEndDate'
					control={formContext.control}
				/>
				<S.SubFormContainer>
					<S.SubFormHeader>
						<S.SubFormTitle>신청인 정보</S.SubFormTitle>
					</S.SubFormHeader>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='신청인' isRequired>
								<CRInput.SearchSelector
									disabled={confirmMode}
									placeholder='사회복지사 선택'
									currentValue={value}
									items={managerOptions}
									onChange={onChange}
									visibleKey={['birth', 'duty']}
								/>
							</CRInputLabel>
						)}
						name='consenter'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='신청인 생년월일' isRequired>
								<CRInput.Default
									onChange={onChange}
									value={value}
									placeholder='예시) 19280301'
									type='number'
									maxLength={8}
								/>
							</CRInputLabel>
						)}
						name='birthDt'
						control={formContext.control}
					/>
				</S.SubFormContainer>
				<S.SubFormContainer>
					<S.SubFormHeader>
						<S.SubFormTitle>수급자 정보</S.SubFormTitle>
					</S.SubFormHeader>
					<Controller
						render={({ field: { onChange, onBlur, value, ref } }) => (
							<CRInputLabel label='수급자명' isRequired>
								<CRInput.Default
									ref={ref}
									onBlur={onBlur}
									placeholder='수급자명 입력'
									onChange={onChange}
									value={value}
								/>
							</CRInputLabel>
						)}
						name='recipientNm'
						control={formContext.control}
					/>
					<Controller
						render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
							<CRInputLabel label='수급자 주민등록번호' isRequired>
								<div
									style={{
										position: 'relative',
									}}>
									<CRInput.Default
										type='number'
										maxLength={13}
										ref={ref}
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										placeholder='주민등록번호 입력'
									/>
								</div>
							</CRInputLabel>
						)}
						name='recipientRsdnNo'
						control={formContext.control}
					/>
					<CRInputLabel label='장기요양 등급' isRequired>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.Selector
									currentValue={value}
									onChangeValue={onChange}
									items={gradeOptions}
									placeholder='등급 선택'
								/>
							)}
							name='longTermGradeCd'
							control={formContext.control}
						/>
					</CRInputLabel>
					<Controller
						render={({ field: { onChange, onBlur, value, ref } }) => (
							<CRInputLabel label='장기요양 인정번호' isRequired>
								<S.DateContainer>
									<CRInput.Default
										ref={ref}
										onBlur={onBlur}
										placeholder='L1234567890'
										onChange={onChange}
										value={value}
										maxLength={11}
									/>
								</S.DateContainer>
							</CRInputLabel>
						)}
						name='longTermNo'
						control={formContext.control}
					/>
					<CRInputLabel label='주소 입력' isRequired>
						{confirmMode ? (
							<Controller
								render={({ field: { onChange, onBlur, value, ref } }) => (
									<CRInput.Default placeholder='주소 입력' value={value} onChange={onChange} />
								)}
								name='recipientAddr'
								control={formContext.control}
							/>
						) : (
							<>
								<Controller
									render={({ field: { onChange, onBlur, value, ref } }) => (
										<CRInput.Address
											type='default'
											onChangeBaseAddress={handleChangeBaseAddress}
											baseAddress={baseAddr}
										/>
									)}
									name='recipientBaseAddr'
									control={formContext.control}
								/>
								<Controller
									render={({ field: { onChange, onBlur, value, ref } }) => (
										<CRInput.Default
											onChange={onChange}
											value={value}
											placeholder='상세주소 입력'
										/>
									)}
									name='recipientDetailAddr'
									control={formContext.control}
								/>
							</>
						)}
					</CRInputLabel>
				</S.SubFormContainer>
				{service?.map((item, index) => (
					<S.SubFormContainer key={item.id}>
						<S.SubFormHeader>
							<S.SubFormTitle>급여 이용{index + 1}</S.SubFormTitle>
							{service?.length > 1 && (
								<CRButton.Default
									type='outlined'
									size='xSmall'
									onClick={() => handleClickDeleteService(item.id)}>
									삭제
								</CRButton.Default>
							)}
						</S.SubFormHeader>
						<CRInputLabel label='서비스 종류' isRequired>
							<Controller
								render={({ field: { onChange, value } }) => (
									<CRInput.Selector
										currentValue={value}
										onChangeValue={onChange}
										items={serviceTypesCodes}
										placeholder='서비스 종류 선택'
									/>
								)}
								name={`service.${index}.serviceType`}
								control={formContext.control}
							/>
						</CRInputLabel>
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CRInputLabel label='서비스 내용' isRequired>
									<S.DateContainer>
										<CRInput.Default
											ref={ref}
											onBlur={onBlur}
											placeholder='서비스 내용 입력'
											onChange={onChange}
											value={value}
										/>
									</S.DateContainer>
								</CRInputLabel>
							)}
							name={`service.${index}.serviceDesc`}
							control={formContext.control}
						/>
						<Controller
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<CRInputLabel label='수가' isRequired>
									<S.DateContainer>
										<CRInput.Default
											type='number'
											ref={ref}
											onBlur={onBlur}
											placeholder='수가 입력'
											suffix='원'
											onChange={onChange}
											value={value}
										/>
									</S.DateContainer>
								</CRInputLabel>
							)}
							name={`service.${index}.serviceFee`}
							control={formContext.control}
						/>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInputLabel label='횟수/월' isRequired>
									<S.DateContainer>
										<CRInput.Default
											type='number'
											placeholder='횟수/월 입력'
											suffix='회'
											onChange={onChange}
											value={value}
										/>
									</S.DateContainer>
								</CRInputLabel>
							)}
							name={`service.${index}.serviceNumberOfMonth`}
							control={formContext.control}
						/>
						<Controller
							render={() => (
								<CRInputLabel
									label='금액'
									isRequired
									message={
										<S.LabelDescription>수가와 횟수/월에 따라 자동 계산됩니다.</S.LabelDescription>
									}>
									<CRInput.Default
										disabled
										suffix='원'
										placeholder='0'
										type='number'
										maxLength={8}
										value={
											String(Number(item.serviceNumberOfMonth) * Number(item.serviceFee)) || '0'
										}
									/>
								</CRInputLabel>
							)}
							name='birthDt'
							control={formContext.control}
						/>
					</S.SubFormContainer>
				))}
				{service?.length < 6 && (
					<CRButton.IconButton
						palette='gray'
						type='tonal'
						iconLeft={Assets.icon.add}
						onClick={handleClickAddService}>
						급여 이용 추가
					</CRButton.IconButton>
				)}
			</S.SubFormContainer>
		</S.Container>
	);
}
