import React, { ReactNode } from 'react';

import * as dS from './styles';
import * as gS from './grayStyles';
import {
	InformationSheetDividerProperties,
	InformationSheetHighlightSectionProperties,
	InformationSheetProperties,
	InformationSheetSectionProperties,
} from './type';
import HighlightSectionInformation from './Information/HighlightSectionInformation';
import BaseSectionInformation from './Information/BaseSectionInformation';
import Divider from './Information/Divider';

interface Props {
	items: InformationSheetProperties[][];
	title?: ReactNode;
	type?: 'default' | 'gray';
	align?: 'space-between' | 'flex-start';
	alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
	direction?: 'row' | 'column';
}

function InformationSheet({
	title = '',
	items,
	type = 'default',
	align = 'space-between',
	alignItems,
	direction = 'row',
}: Props): React.ReactElement {
	const S = type === 'default' ? dS : gS;

	const renderItem = (
		item:
			| InformationSheetSectionProperties
			| InformationSheetHighlightSectionProperties
			| InformationSheetDividerProperties,
		key?: string,
	) => {
		if (item.type === 'divider') {
			return <Divider key={key} item={item} type={type} />;
		}
		if (item.type === 'highlight') {
			return <HighlightSectionInformation key={key} item={item} type={type} />;
		}
		return (
			<BaseSectionInformation
				key={key}
				item={item}
				type={type}
				align={align}
				alignItems={alignItems}
				direction={direction}
			/>
		);
	};

	return (
		<S.Container>
			{!!title && <S.Title>{title}</S.Title>}
			{items.map((list, index: number) => {
				const key = `${index}`;
				return (
					<S.Item key={key}>
						{list.map((item, itemIndex) => {
							const itemKey = `${index}-${itemIndex}`;
							return renderItem(item, itemKey);
						})}
					</S.Item>
				);
			})}
		</S.Container>
	);
}

export default React.memo(InformationSheet);
