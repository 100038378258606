import React, { useCallback } from 'react';

import CRTable from 'components/base/CRTable';

import CRButton from 'components/base/CRButton';
import dayjs from 'dayjs';
import { QueryObserverResult } from '@tanstack/react-query';
import { GetWorkExecuteLogDTO, WorkExecuteLogIssueEdocRequest } from 'types/api/workExecuteLog';
import { displayPadTime } from 'lib';
import CRStatus from 'components/base/CRStatus';
import { useLocation, useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import useDialog from 'lib/hook/util/useDialog';
import { useEFormFileName, useMyAccountInfo } from 'lib/hook/react-query';
import { VISIT_STATE_STATUS_CONFIG } from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogVisitForm/constant';
import { WRITE_COMPLETE_YN_CONFIG } from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogBasicInfoForm/constants';
import { ESignWayCode, ESignWayDialog } from 'components/domain/dialog/ESignDialog';
import { ESignRequestForm, LastESignStateCd } from 'types/view/eDoc';
import { useIssueWorkExecuteLog } from 'lib/hook/react-query/mutation/workExecuteLog';
import { ResponseCode } from 'types/api';
import { Toast } from 'components/base/CRToast';
import CRDialog from 'components/base/CRDialog';
import createWorkExecuteLogPreviewEdocParam from './createWorkExecuteLogPreviewEdocParam';

import * as S from './styles';
import { WORK_EXECUTE_LOG_LIST_TABLE_HEADER_CONFIG } from './constant';
import { ServiceTypeCd } from '../RecordingSheetTable/serviceEdocParam';

interface Props {
	items?: GetWorkExecuteLogDTO[] | null;
	offset?: number;
	onRefresh: () => Promise<QueryObserverResult<GetWorkExecuteLogDTO[] | null, unknown>>;
}

function RecipientWorkExecuteLogTable({
	items = [],
	onRefresh,
	offset = 0,
}: Props): React.ReactElement {
	const { hideDialog } = useDialog();
	const { mutateAsync: issueWorkExecuteLog } = useIssueWorkExecuteLog((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			onRefresh();
			hideDialog();
		}
	});
	const { mutateAsync: getEFormFileName } = useEFormFileName();
	const { showDialog } = useDialog();
	const { data: myAccountInfo } = useMyAccountInfo();
	const navigate = useNavigate();
	const location = useLocation();
	const handleClickEditEducation = (item?: GetWorkExecuteLogDTO) => {
		if (!item) return;
		navigate(
			`${location.pathname}/${RouterPath.recipient().workExecuteLogDetail.uniqueKey}/${
				item.workExecuteLogId
			}/${RouterPath.recipient().workExecuteLog.key}`,
		);
	};

	const handleClickPreview = async (item?: GetWorkExecuteLogDTO) => {
		if (!item) return;
		if (
			item?.edocNo &&
			[LastESignStateCd.완료, LastESignStateCd.서명대기, LastESignStateCd.상태오류].includes(
				item.lastEsignStateCd as LastESignStateCd,
			)
		) {
			showDialog(() => <EDocNoDialog viewerType='report' edocNo={item.edocNo} />);
		} else {
			const fileNameRes = await getEFormFileName({
				paperTypeCd: 'CMN119.0073',
				targetDateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
			});
			if (item && myAccountInfo && fileNameRes?.data?.eformFileNm) {
				const eDocParamValue = createWorkExecuteLogPreviewEdocParam(
					myAccountInfo,
					item,
					fileNameRes?.data?.eformFileNm,
				);
				showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={eDocParamValue} />);
			}
		}
	};

	const onSubmit = async (data: ESignRequestForm, item: GetWorkExecuteLogDTO) => {
		let nonFaceError = false;

		if (data?.eSignWayCd?.[0]?.value === ESignWayCode.비대면서명) {
			if (!item?.clientNm || !item?.clientId) {
				nonFaceError = true;
			}

			if (!item?.employeeId1 || !item?.employeeNm1) {
				nonFaceError = true;
			}

			if (
				item.serviceTypeCd === ServiceTypeCd.방문목욕 &&
				(!item?.employeeId2 || !item?.employeeNm2)
			) {
				nonFaceError = true;
			}
		}

		if (nonFaceError) {
			hideDialog();
			showDialog(() => (
				<CRDialog
					showCloseButton={false}
					title='입력 내용을 보완해주세요.'
					body={
						<S.DialogTextContainer>
							{/* eslint-disable-next-line */}
								비대면 서명 요청 문서는 '상담자'와 '급여제공자 성명'을 입력한 후 발송할 수 있습니다.
							입력 내용을 확인하고 보완한 뒤 다시 시도해주세요.
						</S.DialogTextContainer>
					}
					footer={
						<S.NonVisitAlertButtonContainer>
							<CRButton.Default palette='primary' type='outlined' onClick={hideDialog}>
								확인
							</CRButton.Default>
						</S.NonVisitAlertButtonContainer>
					}
				/>
			));
		} else {
			const params: WorkExecuteLogIssueEdocRequest = {
				workExecuteLogId: item.workExecuteLogId,
				esignWayCd: data.eSignWayCd?.[0]?.value,
			};
			if (data.eSignWayCd?.[0]?.value === ESignWayCode.대면서명) {
				params.facingManagerId = data.receiver.data?.memberAccountId;
				params.facingManagerPhoneUsageDivCd = data.facingManagerPhoneUsageDivCd?.[0]?.value;
			}

			const response = await issueWorkExecuteLog(params);
			if (response?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 발송 요청을 하였습니다.');
				onRefresh();
			} else {
				Toast.error(response?.message);
			}
			hideDialog();
		}
	};

	const handleClickIssue = async (item?: GetWorkExecuteLogDTO) => {
		if (item && myAccountInfo) {
			showDialog(() => <ESignWayDialog onSubmit={onSubmit} item={item} />);
		}
	};

	const shouldDisableButton = (item?: GetWorkExecuteLogDTO): boolean => {
		if (!item?.lastEsignStateCd) return false;

		const validStates = [
			LastESignStateCd.완료,
			LastESignStateCd.서명취소,
			LastESignStateCd.상태오류,
		];
		return !validStates.includes(item.lastEsignStateCd as LastESignStateCd);
	};

	const renderESignStateCd = (value: string, item?: GetWorkExecuteLogDTO) => {
		if (!value || value === LastESignStateCd.서명취소) return '';
		return item?.lastEsignStateNm;
	};

	const renderOption = (value: number, item?: GetWorkExecuteLogDTO) => (
		<S.ButtonContainer>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleClickPreview(item)}>
				미리보기
			</CRButton.Default>
			<CRButton.Default
				disabled={shouldDisableButton(item)}
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleClickIssue(item)}>
				발송
			</CRButton.Default>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={() => handleClickEditEducation(item)}>
				{item?.lastEsignStateCd
					? [LastESignStateCd.완료, LastESignStateCd.서명취소].includes(
							item?.lastEsignStateCd as LastESignStateCd,
					  )
						? '수정'
						: '조회'
					: '수정'}
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderVisitDate = (value: string, item?: GetWorkExecuteLogDTO) => {
		const visitDate = value ? dayjs(value).format('YYYY.MM.DD') : '';
		return <span>{`${visitDate}`}</span>;
	};

	const renderVisitSchedule = (value: string, item?: GetWorkExecuteLogDTO) => {
		const startTime = item?.serviceStartTime ? displayPadTime(item.serviceStartTime) : '';
		const endTime = item?.serviceEndTime ? ` ~${displayPadTime(item.serviceEndTime)}` : '';

		return <span>{`${startTime}${endTime}`}</span>;
	};

	const renderVisitTime = (value: string, item?: GetWorkExecuteLogDTO) => {
		if (!item?.startTime || !item?.endTime) return '';
		const startTime = item.startTime ? displayPadTime(item.startTime) : '';
		const endTime = item.endTime ? displayPadTime(item.endTime) : '';

		const [startHours, startMinutes] = startTime.split(':').map(Number);
		const [endHours, endMinutes] = endTime.split(':').map(Number);

		// 시간과 분을 총 분으로 변환
		const totalMinutesStart = startHours * 60 + startMinutes;
		let totalMinutesEnd = endHours * 60 + endMinutes;

		// endTime이 00시 이후로 넘어갔다면 24시간을 더해 다음날로 처리
		if (totalMinutesEnd < totalMinutesStart) {
			totalMinutesEnd += 24 * 60;
		}

		// 시간 차이 계산
		const differenceInMinutes = Math.abs(totalMinutesEnd - totalMinutesStart);

		return (
			<span>{`${startTime}${endTime ? ` ~${endTime}` : ''}${
				!Number.isNaN(differenceInMinutes) ? `(${differenceInMinutes}분)` : ''
			}`}</span>
		);
	};

	const renderWorkExecuteLogStatus = useCallback(
		(status: string) => <CRStatus options={VISIT_STATE_STATUS_CONFIG}>{status}</CRStatus>,
		[],
	);

	const renderWriteCompleteStatus = useCallback(
		(completeYn: boolean) => (
			<CRStatus options={WRITE_COMPLETE_YN_CONFIG}>
				{completeYn ? '작성 완료' : '작성 예정'}
			</CRStatus>
		),
		[],
	);

	return (
		<S.Container>
			<CRTable.Root>
				<CRTable.Head heads={WORK_EXECUTE_LOG_LIST_TABLE_HEADER_CONFIG} offset={offset} />
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.workExecuteLogId}
							item={item}
							renderKeys={[
								'workExecuteLogStateCd',
								'esignWayNm',
								'lastEsignStateCd',
								'visitDt',
								'startTime',
								'endTime',
								'visitorNm',
								'workExecuteLogId',
							]}
							customRender={{
								workExecuteLogStateCd: renderWorkExecuteLogStatus,
								writeCompleteYn: renderWriteCompleteStatus,
								visitDt: renderVisitDate,
								startTime: renderVisitSchedule,
								endTime: renderVisitTime,
								workExecuteLogId: renderOption,
								lastEsignStateCd: renderESignStateCd,
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default RecipientWorkExecuteLogTable;
