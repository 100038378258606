import React, { useMemo } from 'react';
import EmployeeBasicInfoForm from 'components/domain/form/EmployeeBasicInfoForm';
import OtherJobForm from 'components/domain/form/OtherJobForm';
import { useCommonCodes, useEmployeeResignInfo, useMyAccountInfo } from 'lib/hook/react-query';
import useEmployeePage from 'lib/hook/employee/useEmployeePage';
import CRButton from 'components/base/CRButton';
import EmployeePayInfoForm from 'components/domain/form/EmployeePayInfoForm';
import EmployeeBasicStatusFormView from 'components/domain/form/EmployeeBasicStatusForm';
import CRSpinner from 'components/base/CRSpinner';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'common/router';
import { ResponseCode } from 'types/api';
import useDialog from 'lib/hook/util/useDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { Toast } from 'components/base/CRToast';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { EDocTemplateType } from 'types/view/eDoc';
import EmployeeDeleteDialog from 'components/domain/dialog/EmployeeDeleteDialog';
import { WorkStateCd } from 'types/view/employee';
import * as S from './styles';

export default function EmployeeBasicTab(): React.ReactElement {
	const { currentEmployee, employeeDetailData, employeeDetailLoading } = useEmployeePage();
	const navigate = useNavigate();
	const { data: myAccountInfo } = useMyAccountInfo();
	const hasPermission = useHasPermission(['센터장']);

	const { showDialog } = useDialog();

	const { mutateAsync: getResignInfo } = useEmployeeResignInfo();

	const { data: commonCodes } = useCommonCodes({
		comCdGroupNms: ['CMN070', 'CMN020', 'CMN149', 'CMN068', 'CMN133', 'CMN037', 'CMN163', 'CMN180'],
	});

	const otherJobData = employeeDetailData?.otherCorpWorks?.length
		? employeeDetailData?.otherCorpWorks
		: [];

	const options = useMemo(() => {
		const duty =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN070' && e?.comCdDesc === '1')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const day =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN020')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const disabilityLevel =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN149')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const workState =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN068' && e.comCdDesc === '1')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const payType =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN133')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const bank =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN037')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const healthCheckKind =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN163')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		const docReceiveMethod =
			commonCodes
				?.filter((e) => e.comCdGroupNm === 'CMN180')
				?.map((option) => ({
					label: option.korComCdAliasNm,
					value: option.comCdId,
				})) ?? [];
		return {
			duty,
			day,
			disabilityLevel,
			workState,
			payType,
			bank,
			healthCheckKind,
			docReceiveMethod,
		};
	}, [commonCodes]);

	const handleNavigateResignPage = async () => {
		const resignInfo = await getResignInfo({
			centerId: myAccountInfo?.centerId,
			employeeId: currentEmployee?.employeeId ? String(currentEmployee?.employeeId) : undefined,
		});

		if (resignInfo?.code === ResponseCode.SUCCESS) {
			if (hasPermission) {
				navigate(`${RouterPath.employee().tab.key}/${currentEmployee?.employeeId}/resign`);
			} else if (resignInfo.data?.lastEsignYn) {
				navigate(`${RouterPath.employee().tab.key}/${currentEmployee?.employeeId}/resign`);
			} else {
				Toast.error('아직 사직서에 서명 전입니다. 서명 후 다시 시도해 주시길 바립니다.');
			}
		} else {
			showDialog(({ hideDialog }) => (
				<DefaultDialog
					title='사직서 발급 필요'
					content='퇴사 상태로 변경하려면 사직서를 발급받고 서명을 받아야합니다.'
					hideDialog={hideDialog}
					cancelOption={{
						text: '취소',
					}}
					successOption={{
						text: '확인',
						successCallback: () => {
							navigate(
								`/employee/${currentEmployee?.employeeId}/documents?from=${EDocTemplateType.사직서}`,
							);
							hideDialog();
						},
					}}
				/>
			));
		}
	};

	const handleClickDeleteEmployee = () => {
		showDialog(() => <EmployeeDeleteDialog currentEmployee={currentEmployee} />);
	};

	const isResign = employeeDetailData?.workStateCd === WorkStateCd.퇴사;

	return (
		<S.Container>
			{employeeDetailLoading ? (
				<CRSpinner />
			) : (
				<>
					<EmployeeBasicStatusFormView />
					<EmployeeBasicInfoForm options={options} item={employeeDetailData} />
					<OtherJobForm options={options} item={otherJobData} />
					<EmployeePayInfoForm options={options} item={employeeDetailData} />
					<S.ButtonContainer>
						{!isResign && (
							<S.WithdrawalButtonContainer>
								<CRButton.Default type='outlined' onClick={handleNavigateResignPage}>
									퇴사
								</CRButton.Default>
							</S.WithdrawalButtonContainer>
						)}
						{hasPermission ? (
							<CRButton.Default type='tonal' palette='gray' onClick={handleClickDeleteEmployee}>
								삭제
							</CRButton.Default>
						) : (
							<RDTooltip content={<>센터장만 직원 정보를 삭제할 수 있습니다.</>} side='right'>
								<CRButton.Default disabled>삭제</CRButton.Default>
							</RDTooltip>
						)}
					</S.ButtonContainer>
				</>
			)}
		</S.Container>
	);
}
