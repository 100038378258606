import React, { useMemo } from 'react';

import InformationTable from 'components/ui/InformationTable';
import CRRequiredMark from 'components/base/CRRequiredMark';
import CRButton from 'components/base/CRButton';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import CRStatus from 'components/base/CRStatus';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { Toast } from 'components/domain/../base/CRToast';
import UploadContractDocument from 'components/domain/dialog/UploadContractDocument';
import CheckHourlyWageDialog from 'components/domain/dialog/CheckHourlyWageDialog';
import useContractChangeEmployeeTask from 'lib/hook/view/contract/useContractChangeEmployeeTask';
import {
	useContractDocument,
	useContractSendStatus,
	useCreateEmployeeDocument,
	useGetEmployeeDocument,
	useSendEmployeeContract,
} from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';

import { ESignRequestForm } from 'types/view/eDoc';
import { SendEmployeeContractRequest } from 'types/api/contract';
import { ESignWayCode, ESignWayDialog } from 'components/domain/dialog/ESignDialog';
import { CONTRACT_LIST_TABLE_HEADER } from './constant';
import * as S from './styles';

function ChangeEmployeeContractDocumentForm(): React.ReactElement {
	const { form, disabled, validateCreateDocument } = useContractChangeEmployeeTask();
	const { showDialog, hideDialog } = useGlobalLayout();
	const { data: contractSendStatus, refetch: refetchContractSendStatus } = useContractSendStatus({
		serviceContractId: form.getValues('service.serviceContractId'),
	});
	const { data: contractDocument, refetch: refetchContractDocument } = useContractDocument({
		serviceContractIds: [form.getValues('service.serviceContractId')],
	});
	const createEmployeeDocument = useCreateEmployeeDocument();
	const getEmployeeDocument = useGetEmployeeDocument();
	const sendEmployeeContract = useSendEmployeeContract();

	const sendList = useMemo(() => {
		const employeeSend = (form.getValues('employees') ?? []).map((item) => {
			const contractEdocSendResponse = (contractSendStatus ?? []).find(
				(status) =>
					status.paperTypeCd === 'CMN119.0051' &&
					status?.employeeId === item.base?.employeeId &&
					status.serviceTypeCd === form.getValues('service').serviceTypeCd,
			)?.contractEdocSendResponse;
			return {
				title: `직원 ${item.base.employeeNm}(${item?.base.employeeId})`,
				items: [
					{
						type: 'employee',
						title: '직원 계약서',
						isRequired: true,
						fileName: '직원_계약서.eform',
						status: contractEdocSendResponse?.esigns[0]?.esignStateCd ?? 'CMN031.10',
						data: {
							serviceContractId: form.getValues('service.serviceContractId'),
							employeeId: item.base.employeeId,
							serviceTypeCd: form.getValues('service').serviceTypeCd,
							monthPerWorkHourCnt: Number(
								form.getValues('service')?.use?.monthPerWorkHourCnt ?? '0',
							),
							receiverName: item.base.employeeNm,
						},
						document: contractDocument?.find(
							(target) =>
								target.paperTypeCd === 'CMN119.0051' &&
								target?.employeeId === item.base?.employeeId &&
								target.serviceTypeCd === form.getValues('service').serviceTypeCd,
						),
					},
				],
			};
		});

		return employeeSend as {
			title: string;
			items: {
				type: 'employee' | 'recipient';
				title: string;
				isRequired: boolean;
				fileName: string;
				status: string;
				data: any;
				document: any;
			}[];
		}[];
	}, [
		form.getValues('employees'),
		form.getValues('service'),
		contractSendStatus,
		contractDocument,
	]);

	const handleClickEmployeeSend = async (data: any) => {
		const onSubmitEmployee = async (eSignWayData: ESignRequestForm, item: any) => {
			try {
				if (sendEmployeeContract.isLoading) return false;
				const param: SendEmployeeContractRequest =
					eSignWayData.eSignWayCd?.[0]?.value === ESignWayCode.대면서명
						? {
								employeeContractId: item.document.contractDocumentId,
								esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
								facingManagerId: eSignWayData.receiver?.data?.memberAccountId,
								facingManagerPhoneUsageDivCd:
									eSignWayData?.facingManagerPhoneUsageDivCd?.[0]?.value,
						  }
						: {
								employeeContractId: item.document.contractDocumentId,
								esignWayCd: eSignWayData.eSignWayCd?.[0]?.value,
						  };
				await sendEmployeeContract.mutateAsync(param);
				refetchContractSendStatus();
				hideDialog();
				Toast.success('계약서를 성공적으로 발송했습니다.');
			} catch {
				Toast.error('계약서 발송에 실패하였습니다. 잠시 후 다시 시도해주세요.');
			}
			return undefined;
		};
		showDialog(() => <ESignWayDialog onSubmit={onSubmitEmployee} item={data} />);
	};

	const handleClickCreateDocument = async (item: any) => {
		try {
			if (!validateCreateDocument?.(item?.data?.employeeId)) return;
			if (item.type === 'employee') {
				if (createEmployeeDocument.isLoading) return;

				showDialog(({ hideDialog }) => (
					<CheckHourlyWageDialog
						employeeName={item.data.receiverName}
						config={{
							serviceContractId: item.data.serviceContractId,
							employeeId: item.data.employeeId,
							serviceTypeCd: item.data.serviceTypeCd,
							monthPerWorkHourCnt: item.data.monthPerWorkHourCnt,
						}}
						onClose={hideDialog}
						onConfirm={async (serviceEmployeeWage: number | null) => {
							await createEmployeeDocument.mutateAsync({
								serviceContractId: item.data.serviceContractId,
								employeeId: item.data.employeeId,
								serviceTypeCd: item.data.serviceTypeCd,
								monthPerWorkHourCnt: item.data.monthPerWorkHourCnt,
								serviceEmployeeWage,
							});
							Toast.success('계약서를 성공적으로 발급했습니다.');
							refetchContractDocument();
							hideDialog();
						}}
					/>
				));

				return;
			}
		} catch (error: any) {
			Toast.error(error.message ?? '계약서 발급에 실패하였습니다. 잠시 후 다시 시도해주세요.');
		}
	};

	const handleClickReCreateEmployeeDocument = (item: any) => {
		showDialog(({ hideDialog }) => (
			<CheckHourlyWageDialog
				employeeName={item.data.receiverName}
				config={{
					serviceContractId: item.data.serviceContractId,
					employeeId: item.data.employeeId,
					serviceTypeCd: item.data.serviceTypeCd,
					monthPerWorkHourCnt: item.data.monthPerWorkHourCnt,
				}}
				onClose={hideDialog}
				onConfirm={async (serviceEmployeeWage: number | null) => {
					await createEmployeeDocument.mutateAsync({
						serviceContractId: item.data.serviceContractId,
						employeeId: item.data.employeeId,
						serviceTypeCd: item.data.serviceTypeCd,
						monthPerWorkHourCnt: item.data.monthPerWorkHourCnt,
						serviceEmployeeWage,
					});
					refetchContractSendStatus();
					refetchContractDocument();
					hideDialog();
					Toast.success('계약서를 성공적으로 재발급했습니다.');
				}}
			/>
		));
	};

	const handleClickReCreateDocument = async (item: any) => {
		if (!validateCreateDocument?.(item?.data?.employeeId)) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				hideDialog={hideDialog}
				title='계약서 재발급'
				content='기존 문서를 취소하고 재발급 받겠습니까?'
				successOption={{
					text: '재발급',
					successCallback: async () => {
						try {
							if (item.type === 'employee') {
								if (createEmployeeDocument.isLoading) return;
								hideDialog();
								handleClickReCreateEmployeeDocument(item);
							}
						} catch {
							Toast.error('계약서 재발급에 실패하였습니다. 잠시 후 다시 시도해주세요.');
						}
					},
				}}
			/>
		));
	};

	const handleClickPreview = async (item: any) => {
		const data = await getEmployeeDocument.mutateAsync({
			employeeContractId: item.document.contractDocumentId,
		});

		if (!data) return;
		showDialog(() => <EDocNoDialog viewerType='report' eDocParamValue={JSON.parse(data)} />);
	};

	const handleClickSend = (item: any) => {
		if (item.type === 'employee') {
			handleClickEmployeeSend(item);
			return;
		}
	};

	const handleClickUpload = (item: any) => {
		if (item.type === 'employee') {
			showDialog(() => (
				<UploadContractDocument
					contractDocumentId={item.document.contractDocumentId}
					type='employee'
				/>
			));
			return;
		}
	};

	return (
		<S.Container>
			{sendList.map((item) => (
				<S.SectionContainer>
					<S.SectionTitle>{item.title}</S.SectionTitle>
					<InformationTable
						items={[
							CONTRACT_LIST_TABLE_HEADER,
							...item.items.map(
								(send) =>
									[
										{
											type: 'value',
											value: (
												<div>
													{send.title} {send.isRequired && <CRRequiredMark />}
												</div>
											),
										},
										{
											type: 'value',
											value: send.fileName,
										},
										{
											type: 'value',
											value: (
												<CRStatus
													options={[
														{
															key: '',
															label: '발송전',
															color: 'gray',
														},
														{
															key: 'CMN031.10',
															label: '발송전',
															color: 'gray',
														},
														{
															key: 'CMN031.20',
															label: '완료',
															color: 'green',
														},
														{
															key: 'CMN031.30',
															label: '진행중',
															color: 'gray',
														},
														{
															key: 'CMN031.40',
															label: '제출',
															color: 'gray',
														},
														{
															key: 'CMN031.50',
															label: '완료',
															color: 'green',
														},
													]}>
													{send.status}
												</CRStatus>
											),
										},
										{
											type: 'value',
											value: (
												<S.ButtonContainer>
													{!send.document ? (
														<CRButton.Default
															size='xSmall'
															type='outlined'
															palette='gray'
															onClick={() => handleClickCreateDocument(send)}
															disabled={disabled}>
															발급
														</CRButton.Default>
													) : (
														<>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickReCreateDocument(send)}
																disabled={disabled}>
																재발급
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickPreview(send)}>
																미리보기
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickSend(send)}>
																{send.status === 'CMN031.10' ? '발송' : '재발송'}
															</CRButton.Default>
															<CRButton.Default
																size='xSmall'
																type='outlined'
																palette='gray'
																onClick={() => handleClickUpload(send)}>
																업로드
															</CRButton.Default>
														</>
													)}
												</S.ButtonContainer>
											),
										},
									] as InformationTableItemType[],
							),
						]}
					/>
				</S.SectionContainer>
			))}
		</S.Container>
	);
}

export default ChangeEmployeeContractDocumentForm;
