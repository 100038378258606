import { css, styled } from 'styled-components';

export const Container = styled.div<{ $showDays: boolean; $disabled: boolean }>`
	${(props) =>
		props.$showDays &&
		css`
			display: flex;
			flex-direction: column;
			gap: 1.6rem;
			padding: 1.6rem;
			border: 0.1rem solid ${(props) => props.theme.colors.gray90};
			border-radius: 0.8rem;
		`}

	${(props) =>
		props.$disabled &&
		css`
			background: ${(props) => props.theme.colors.gray95};
		`}
`;

export const DayContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Days = styled.ul`
	display: flex;
	gap: 0.4rem;
`;

export const Day = styled.li<{ $isSelected?: boolean; $isDisabled?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.4rem;
	height: 2.4rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 50%;
	color: ${(props) => props.theme.colors.gray10};

	font-family: 'Pretendard';
	font-feature-settings: tnum;
	font-variant-numeric: tabular-nums;
	font-style: normal;
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 100%;
	cursor: pointer;

	${(props) =>
		props.$isSelected &&
		css`
			border: 0.1rem solid ${(props) => props.theme.colors.primary60};
			color: ${(props) => props.theme.colors.gray100};
			background: ${(props) => props.theme.colors.primary60};
		`}

	${(props) =>
		props.$isDisabled &&
		props.$isSelected &&
		css`
			border: 0.1rem solid ${(props) => props.theme.colors.gray60};
			color: ${(props) => props.theme.colors.gray100};
			background: ${(props) => props.theme.colors.gray60};
		`};
`;

export const TimeContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.6rem 1.2rem 1.6rem 1.6rem;
	height: 5.6rem;
	gap: 0.8rem;
	box-sizing: border-box;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	box-sizing: border-box;
`;

export const Times = styled.div`
	display: flex;
	flex: 1;
	gap: 0.8rem;
	& > span {
		color: ${(props) => props.theme.colors.gray60};
		width: 1.1rem;
	}
`;

export const Input = styled.input<{ $timeAlign?: 'center' | 'left' }>`
	border: none;
	height: 100%;
	outline: none;
	padding: 0;
	margin: 0;
	width: ${(props) => (props.$timeAlign === 'left' ? '5rem' : '100%')};
	text-align: center;
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};

	&:disabled {
		color: ${(props) => props.theme.colors.gray60};
		/* display: none; */
		cursor: no-drop;
	}

	&::placeholder {
		${(props) => props.theme.typography.body};
		color: ${(props) => props.theme.colors.gray60};
	}
`;

export const Icon = styled.img<{ $isDisabled: boolean }>`
	width: 2.4rem;
	height: 2.4rem;

	${(props) =>
		props.$isDisabled &&
		css`
			${props.theme.filters.gray60};
		`};
`;

export const InputContainer = styled.div<{
	$isDisable?: boolean;
	$hasError?: boolean;
	$showDays?: boolean;
}>`
	/* display: flex; */
	/* gap: 0.8rem; */

	${(props) =>
		props.$isDisable &&
		css`
			background: ${(props) => props.theme.colors.gray90};
			cursor: no-drop;
		`}
	${(props) =>
		props.$hasError &&
		css`
			border: 0.2rem solid ${(props) => props.theme.colors.primary60};
			background: ${(props) => props.theme.colors.primary99};
		`};

	${Icon} {
		${(props) =>
			props.$hasError &&
			css`
				filter: brightness(0) saturate(100%) invert(43%) sepia(26%) saturate(2944%)
					hue-rotate(318deg) brightness(103%) contrast(101%);
			`};
	}

	${Input} {
		${(props) =>
			props.$hasError &&
			css`
				background: ${(props) => props.theme.colors.primary99};
				color: ${(props) => props.theme.colors.primary60};
			`};
	}
`;
