import React, { useCallback, useEffect, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

import Assets from 'assets';
import useEDocClientPage from 'lib/hook/eDoc/useESignInfoPage';
import useWorkExecuteInspectionLog from 'lib/hook/view/workExecuteInspectionLog/useWorkExecuteInspectionLog';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { WorkExecuteLogCode } from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogBasicInfoForm';
import EDocWorkExecuteLogBasicInfoForm from 'components/domain/form/EDocWorkExecuteSheetForm/EDocWorkExecuteLogBasicInfoForm';
import EDocWorkExecuteLogCheckForm from 'components/domain/form/EDocWorkExecuteSheetForm/EDocWorkExecuteLogCheckForm';
import EDocWorkExecuteLogVisitForm from 'components/domain/form/EDocWorkExecuteSheetForm/EDocWorkExecuteLogVisitForm';
import EDocWorkExecuteLogGuardianConsultForm from 'components/domain/form/EDocWorkExecuteSheetForm/EDocWorkExecuteLogGuardianConsultForm';
import EDocWorkExecuteLogIASalaryOfferPlanForm from 'components/domain/form/EDocWorkExecuteSheetForm/EDocWorkExecuteLogIASalaryOfferPlanForm';
import EDocWorkExecuteLogRecognitiveActivityProgramForm from 'components/domain/form/EDocWorkExecuteSheetForm/EDocWorkExecuteLogRecognitiveActivityProgramForm';
import CRButton from 'components/base/CRButton';
import { BaseResponse } from 'types/api';

import * as S from './styles';

export const WorkExecuteFormList = ['CMN119.0073'];

interface Props {
	onConfirm: () => void;
}

function EDocWorkExecuteForm({ onConfirm }: Props): React.ReactElement {
	const { eDocInfo, authInfo, setEDocConfirmResult } = useEDocClientPage();
	const {
		form,
		setEdocData,
		recipient,
		updateWorkExecuteInspectionLog,
		setUpdateEDocConfirmResultHandler,
		setType,
		setBirthDt,
		validate,
	} = useWorkExecuteInspectionLog();
	const [forms, setForms] = useState<
		{
			id: string;
			label: string;
			component: JSX.Element;
		}[]
	>();

	const handleUpdateEDocConfirmResult = useCallback((result: BaseResponse) => {
		if (result) {
			setEDocConfirmResult({ code: result.code, message: result.message });
			onConfirm();
		}
	}, []);

	const workExecuteLogDivCd = form.getValues('workExecuteLogDivCd');

	useEffect(() => {
		const newForms = [
			{
				id: '#1',
				label: '기본 정보',
				component: <EDocWorkExecuteLogBasicInfoForm recipient={recipient} disabled={false} />,
			},
			{
				id: '#2',
				label: '방문 정보',
				component: <EDocWorkExecuteLogVisitForm disabled={false} />,
			},
			{
				id: '#4',
				label: '상담',
				component: <EDocWorkExecuteLogGuardianConsultForm disabled={false} />,
			},
			{
				id: '#5',
				label: '급여 및 인지활동 프로그램 제공 확인',
				component: <EDocWorkExecuteLogCheckForm disabled={false} />,
			},
			{
				id: '#6',
				label: '욕구사정 및 급여제공계획',
				component: <EDocWorkExecuteLogIASalaryOfferPlanForm disabled={false} />,
			},
		];

		if (workExecuteLogDivCd?.[0]?.value === WorkExecuteLogCode.프로그램관리자) {
			newForms.splice(2, 0, {
				id: '#3',
				label: '인지활동 프로그램 제공 계획',
				component: <EDocWorkExecuteLogRecognitiveActivityProgramForm disabled={false} />,
			});
		}

		setForms(newForms);
	}, [workExecuteLogDivCd]);

	useEffect(() => {
		if (handleUpdateEDocConfirmResult) {
			setUpdateEDocConfirmResultHandler(() => handleUpdateEDocConfirmResult);
		}
	}, [handleUpdateEDocConfirmResult]);

	useEffect(() => {
		setType('wirte');
		if (eDocInfo) {
			setEdocData(eDocInfo);
		}
	}, [eDocInfo]);

	useEffect(() => {
		if (authInfo?.birthDt) {
			setBirthDt(authInfo?.birthDt);
		}
	}, [authInfo?.birthDt]);

	return (
		<S.Container>
			<S.Header>{`업무수행일지 작성(${recipient?.recipientNm})`}</S.Header>
			<S.FormContainer>
				<Accordion.Root
					type='multiple'
					defaultValue={Array.from({ length: 6 }).map((_, index) => `#${index + 1}`)}>
					{forms?.map((form) => (
						<Accordion.Item value={form.id} key={form.id} asChild>
							<TaskAccordion.Item>
								<Accordion.Header asChild>
									<Accordion.Trigger asChild>
										<TaskAccordion.Trigger href={form.id}>{form.label}</TaskAccordion.Trigger>
									</Accordion.Trigger>
								</Accordion.Header>
								<Accordion.Content asChild>
									<TaskAccordion.Content>{form.component}</TaskAccordion.Content>
								</Accordion.Content>
							</TaskAccordion.Item>
						</Accordion.Item>
					))}
				</Accordion.Root>
			</S.FormContainer>
			<S.Footer>
				<CRButton.IconButton
					size='large'
					disabled={!validate({ ignoreMessage: true })}
					iconLeft={Assets.icon.check}
					onClick={updateWorkExecuteInspectionLog}
					fullSize>
					작성 완료
				</CRButton.IconButton>
			</S.Footer>
		</S.Container>
	);
}

export default EDocWorkExecuteForm;
