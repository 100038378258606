import Colors from 'common/colors';
import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const MONITORING_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '',
		sortKey: '',
		checkbox: true,
	},
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
		textAlign: 'right',
	},
	{
		width: '8.8rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '8.8rem',
		label: '확정 상태',
		sortKey: '확정 상태',
	},
	{
		width: '12rem',
		label: '일자',
		sortKey: '일자',
	},
	{
		width: '12rem',
		label: '일정 계획 시간',
		sortKey: '일정 계획 시간',
	},
	{
		width: '12rem',
		label: '청구 유형',
		sortKey: '청구 유형',
	},
	{
		width: '12rem',
		label: 'RFID 시간',
		sortKey: 'RFID 시간',
	},
	{
		width: '12rem',
		label: '근무 시간',
		sortKey: '근무 시간',
	},
	{
		width: '12rem',
		label: '근무 확인',
		sortKey: '근무 확인',
	},
	{
		width: '12.8rem',
		label: '특정내용 작성',
		sortKey: '특정내용 작성',
	},
	{
		width: '11.2rem',
		label: '특이사항 확인',
		sortKey: '특이사항 확인',
	},
	{
		width: '14.2rem',
		label: '시간 오류 확인',
		sortKey: '시간 오류 확인',
	},
	{
		width: '11.2rem',
		label: '일정 중복 확인',
		sortKey: '일정 중복 확인',
	},
	{
		width: '11.2rem',
		label: '처리 상태',
		sortKey: '처리 상태',
	},
	{
		width: '28rem',
		label: '메모',
		sortKey: '메모',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const MONITORING_BATH_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '5.6rem',
		label: '',
		sortKey: '',
		checkbox: true,
	},
	{
		width: '5.6rem',
		label: '구분',
		sortKey: '구분',
		textAlign: 'right',
	},
	{
		width: '8.8rem',
		label: '상태',
		sortKey: '상태',
	},
	{
		width: '8.8rem',
		label: '확정 상태',
		sortKey: '확정 상태',
	},
	{
		width: '12rem',
		label: '일자',
		sortKey: '일자',
	},
	{
		width: '12rem',
		label: '일정 계획 시간',
		sortKey: '일정 계획 시간',
	},
	{
		width: '9.6rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '12rem',
		label: '청구 유형',
		sortKey: '청구 유형',
	},
	{
		width: '12rem',
		label: 'RFID 시간',
		sortKey: 'RFID 시간',
	},
	{
		width: '12rem',
		label: '근무 시간',
		sortKey: '근무 시간',
	},
	{
		width: '12rem',
		label: '근무 확인',
		sortKey: '근무 확인',
	},
	{
		width: '12.8rem',
		label: '특정내용 작성',
		sortKey: '특정내용 작성',
	},
	{
		width: '11.2rem',
		label: '특이사항 확인',
		sortKey: '특이사항 확인',
	},
	{
		width: '14.2rem',
		label: '시간 오류 확인',
		sortKey: '시간 오류 확인',
	},
	{
		width: '11.2rem',
		label: '일정 중복 확인',
		sortKey: '일정 중복 확인',
	},
	{
		width: '11.2rem',
		label: '처리 상태',
		sortKey: '처리 상태',
	},
	{
		width: '28rem',
		label: '메모',
		sortKey: '메모',
	},
	{
		width: '100%',
		label: '',
		sortKey: '',
	},
];

export const PLAN_COMPLETE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미완료',
		label: '미완료',
		color: 'yellow',
	},
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
];

export const MONITORING_COMPLETE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '대기',
		label: '대기',
		color: 'gray',
		custom: {
			color: Colors.gray60,
			background: Colors.gray90,
		},
	},
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
];

export const CHECK_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미확인',
		label: '미확인',
		color: 'yellow',
	},
	{
		key: '확인 완료',
		label: '확인 완료',
		color: 'green',
	},
];
